import { Select } from "@chakra-ui/react";

function StyledSelect({ children, ...props }) {
  return (
    <Select
      size="lg"
      {...props}
      style={{
        background: "#EFF0F7",
        border: "2px solid #2969B0",
        borderRadius: "12px",
        ...props.style
      }}
    >
      {children}
    </Select>
  );
}

export default StyledSelect;
