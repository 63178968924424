import { Text, useRadioGroup, HStack, RadioGroup } from "@chakra-ui/react";
import CustomRadio from "../components/CustomRadio";

function TernaryRadioGroup({ options, onChange, selectedAnswer }) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "",
    onChange
  });
  const group = getRootProps();
  return (
    <RadioGroup {...group} width="100%" mb={0}>
      <HStack spacing={4} style={{ alignItems: "flex-start" }}>
        {options.map((value, index) => {
          const radio = getRadioProps({ value });
          return (
            <CustomRadio
              key={value}
              {...radio}
              isChecked={index + 1 === selectedAnswer}
              isEndElement={index + 1 === options.length}
            >
              <Text mt={2} fontSize={12} textAlign="center" width="100%">
                {value}
              </Text>
            </CustomRadio>
          );
        })}
      </HStack>
    </RadioGroup>
  );
}

export default TernaryRadioGroup;
