const RESULT_CARD_LOOKUP = {
  LOAN: {
    heading: "Personal Loan",
    guidelink: "/financial-guide/personal-loan",
    description:
      "A regular loan from a bank or building society instead of specific car finance."
  },
  PCP: {
    heading: "Personal Contract Purchase (PCP)",
    guidelink: "/financial-guide/pcp",
    description:
      "The finance option which includes an optional final payment or you can return the car."
  },
  HP: {
    heading: "Hire purchase (HP)",
    guidelink: "/financial-guide/hire-purchase",
    description:
      "The straightforward car finance option, with a deposit and fixed monthly payments."
  },
  LEASE: {
    heading: "Lease",
    guidelink: "/financial-guide/lease",
    description:
      "The alternative to buying a vehicle, long-term rental or leasing."
  }
};

export default RESULT_CARD_LOOKUP;
