import {
  Heading,
  Stack,
  Text,
  Box,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
  Divider
} from "@chakra-ui/react";

import { ArrowBackIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";

import leaseGuideCosts from "../images/lease-guide-costs.svg";
import leaseGuideChart1 from "../images/lease-guide-chart-1.svg";
import leaseGuideChart2 from "../images/lease-guide-chart-2.svg";

function LeaseGuideView() {
  const history = useHistory();
  return (
    <Stack py={2} px={6} flex="1 0 auto">
      <Stack spacing={6}>
        <Link color="#2969B0" onClick={() => history.goBack()}>
          <ArrowBackIcon />
          Back
        </Link>
        <Heading>Personal Contract Hire — Lease</Heading>
        <Heading fontSize={16}>
          The alternative to buying a vehicle, long-term rental or leasing.
        </Heading>
        <Text>
          Generally for new cars, Personal Contract Hire, or Leasing, is a great
          way of getting a car when you aren’t worried about owning the car.
          <br />
          <br />
          It’s relatively simple to understand, and it can be a very affordable
          option.
        </Text>
        <Heading fontSize={16}>How it works</Heading>
        <Text>
          Leasing is a bit like renting a house, but for a specific length of
          time. You pay a small deposit and then pay monthly payments for a set
          amount of time. Once the contract ends, you return the car.
          <br />
          <br />
          Because you’re only paying for the loss in value of the car during
          your lease period, it can work out cheaper than other options,
          especially with new, or relatively new, cars. This allows people to
          save some money, or even get a nicer car.
        </Text>
        <Image src={leaseGuideCosts} />
        <Accordion allowToggle p={0}>
          <AccordionItem px={0}>
            <AccordionButton py={4} px={0}>
              <Heading color="blue.500" textAlign="left" fontSize={20}>
                See how the numbers are calculated
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel px={0}>
              <Stack spacing={5}>
                <Stack spacing={2}>
                  <Heading>1. Loss of value of the car</Heading>
                  <Text>
                    To create the costs of a lease car, they begin by working
                    out how much the car’s value will drop during your lease.
                    This allows them to calculate how much they need to charge
                    you each month.
                  </Text>
                  <Image src={leaseGuideChart1} />
                </Stack>
                <Divider />
                <Stack spacing={2}>
                  <Heading>2. Interest</Heading>
                  <Text>
                    Because you’re not paying everything upfront, leasing
                    companies will add a small amount of interest to your
                    purchase, whilst this is something you can ask for, it’s not
                    an amount that’s always shown
                  </Text>
                </Stack>
                <Stack spacing={2}>
                  <Heading>3. Initial payment and monthly costs</Heading>
                  <Text>
                    These costs are linked together - paying a larger initial
                    payment reduces the monthly cost.
                    <br />
                    <br />
                    To help calculate these, dealers will make the initial
                    payment a multiple of your monthly instalments.
                    <br />
                    <br />
                    As an example, if you’re leasing the car for 3 years (36
                    months), you might also pay an initial payment of 6 months.
                    To get the cost of these, you’d then take the amount you owe
                    them for the total lease, and divide it by 42.
                  </Text>
                  <Image src={leaseGuideChart2} />
                </Stack>
                <Stack spacing={2}>
                  <Heading>4. End of the contract</Heading>
                  <Text>
                    At the end of the contract, you return the car to the
                    dealer, there’s no option to own the car.
                  </Text>
                </Stack>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Box px={6} py={4} bg="#F7F7FC" borderRadius={16} my={3}>
          <Heading fontSize={16} mb={4} lineHeight="24px">
            A few more things to know about Leasing
          </Heading>
          <Text>
            > You don’t have the option to own the car when your lease period
            ends
            <br />
            <br />
            > You don’t get your initial payment or deposit back, but it does
            reduce your monthly payments
            <br />
            <br />
            > You’ll need to agree a mileage upfront so they can calculate the
            monthly payments - exceeding this mileage will potentially result in
            some additional charges at the end.
            <br />
            <br />
            > If you damage the car, more than usual wear and tear, there might
            be charges when you return the car at the end
            <br />
            <br />> It’s possible to get extras with leasing such as a
            maintenance contract, make sure you know what you’re buying so you
            can compare them with other options
          </Text>
        </Box>
      </Stack>
    </Stack>
  );
}

export default LeaseGuideView;
