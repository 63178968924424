import { Box, Flex, List, ListItem, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import logoDark from "../images/logo-dark.svg";
import twitterIcon from "../images/twitter.svg";
import facebookIcon from "../images/facebook.svg";

function Footer() {
  return (
    <Box bg="#163F6D" color="white" px={4} mt={12}>
      <List
        spacing={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
        my={6}
      >
        <ListItem>
          <Link>FAQ</Link>
        </ListItem>
        <ListItem>
          <Link>Support</Link>
        </ListItem>
        <ListItem>
          <Link>Newsroom</Link>
        </ListItem>
        <ListItem>
          <Link>Terms of use</Link>
        </ListItem>
        <ListItem>
          <Link>Privacy notice</Link>
        </ListItem>
        <ListItem>
          <Link>Cookie policy</Link>
        </ListItem>
      </List>
      <Flex justifyContent="space-between" py={3}>
        <Flex justifyContent="space-between" width="80px">
          <img src={twitterIcon} alt="Twitter" />
          <img src={facebookIcon} alt="Facebook" />
        </Flex>
        <Flex>
          <Link as={RouterLink} to="/">
            <img src={logoDark} alt="Clear Drive" />
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Footer;
