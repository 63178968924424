import { Input } from "@chakra-ui/react";

function StyledInput(props) {
  return (
    <Input
      {...props}
      size="lg"
      style={{
        background: "#EFF0F7",
        border: "2px solid #2969B0",
        borderRadius: "12px",
        ...props.style
      }}
    />
  );
}

export default StyledInput;
