import {
  Box,
  Flex,
  Stack,
  Text,
  Heading,
  Link,
  Divider,
  Progress,
  GridItem,
  List,
  ListItem
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { SmallCloseIcon, CheckIcon } from "@chakra-ui/icons";

const scoreToColor = (score) => {
  if (score >= 75 && score <= 100) {
    return "green.500";
  }
  if (score > 50 && score < 75) {
    return "yellow.500";
  }
  if (score < 0 || score === 0 || (score >= 0 && score <= 50)) {
    return "red.500";
  }
};
const scoreToBgColor = (score) => {
  if (score >= 75 && score <= 100) {
    return "white";
  }
  if (score > 50 && score < 75) {
    return "#FFF4DF";
  }
  if (score < 0 || score === 0 || (score >= 0 && score <= 50)) {
    return "#FFF3F8";
  }
};
function ResultCard({
  heading,
  description,
  suitabilityScore,
  featureList,
  guidelink,
  setYellowBg
}) {
  return (
    <GridItem
      borderRadius="16px"
      bg={setYellowBg ? "#FFF4DF" : scoreToBgColor(suitabilityScore * 100)}
      boxShadow="md"
      color="black"
      px={6}
      py={6}
      maxWidth="270px"
      minHeight="200px"
    >
      <Stack>
        <Flex minHeight="55px">
          <Heading size="md" display="block">
            {heading}
          </Heading>
        </Flex>
        <Text minHeight="76px" fontSize={13} mb={1}>
          {description}
        </Text>
        <Text fontSize={13} minHeight="38px" mb={2}>
          <Link color="#2969B0" as={RouterLink} to={`${guidelink}`}>
            More about {heading}
          </Link>
        </Text>
        <Divider />
        <Text fontSize={18}>Suitability</Text>
        <Flex direction="row" alignItems="center">
          <Progress
            colorScheme={
              setYellowBg
                ? "yellow"
                : scoreToColor(suitabilityScore * 100).slice(0, -4)
            }
            value={suitabilityScore * 100 > 0 ? suitabilityScore * 100 : 0}
            borderRadius={16}
            width="90%"
            mr={2}
          />
          <Text
            color={
              setYellowBg ? "yellow.500" : scoreToColor(suitabilityScore * 100)
            }
          >
            {parseFloat(suitabilityScore * 100) > 0
              ? parseFloat(suitabilityScore * 100).toFixed(0)
              : 0}
            %
          </Text>
        </Flex>
        <List spacing={3}>
          {featureList.map((feature) => (
            <ListItem
              key={feature.label}
              position="relative"
              pr="25px"
              fontSize="13px"
            >
              {feature.label}
              {feature.checked ? (
                <Box
                  borderRadius="20px"
                  bg="green.500"
                  w="17px"
                  h="17px"
                  pt="3px"
                  style={{ position: "absolute", right: "0", top: "3px" }}
                  align="center"
                >
                  <CheckIcon
                    color="white"
                    verticalAlign="top"
                    mx="auto"
                    fontSize="11px"
                  />
                </Box>
              ) : (
                !feature.yellow && (
                  <Box
                    borderRadius="50%"
                    bg="red.500"
                    w="17px"
                    h="17px"
                    pt="1.5px"
                    style={{ position: "absolute", right: "0", top: "3px" }}
                    align="center"
                  >
                    <SmallCloseIcon
                      color="white"
                      verticalAlign="top"
                      fontSize="14px"
                    />
                  </Box>
                )
              )}
              {feature.yellow && (
                <Box
                  borderRadius="50%"
                  bg="yellow.500"
                  w="17px"
                  h="17px"
                  pt="0px"
                  fontSize="11px"
                  fontWeight="bold"
                  color="#FFFFFF"
                  style={{ position: "absolute", right: "0", top: "4px" }}
                  align="center"
                >
                  !
                </Box>
              )}
            </ListItem>
          ))}
        </List>
      </Stack>
    </GridItem>
  );
}

export default ResultCard;
