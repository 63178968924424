import {
  Button,
  Box,
  Heading,
  Stack,
  Text,
  Link,
  FormControl
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";

// import StyledLink from "../components/StyledLink";
import StyledCurrencyInput from "../components/StyledCurrencyInput";

function DepositView() {
  // const quizAnswers = JSON.parse(localStorage.getItem("quizAnswers"));
  // const despositAnswer = quizAnswers[2];
  const { fName, lName, carReg, carPrice } = useParams();

  return (
    <Formik initialValues={{ deposit: "", tradeIn: "" }}>
      {({ values, isValid, dirty }) => {
        return (
          <Stack spacing={7} py={2} px={4} flex="1 0 auto" minHeight="500px">
            <Text fontSize="12px" style={{ textTransform: "uppercase" }}>
              Deposit & Mileage
            </Text>
            <Form autoComplete="off">
              <Stack spacing={8}>
                {/* {(despositAnswer === 1 || despositAnswer === 3) && ( */}
                <>
                  <Stack spacing={2}>
                    <Heading size="sm" lineHeight="1.8">
                      How much money do you have for an upfront payment?
                    </Heading>
                    <Text fontSize={13} lineHeight="1.8" mb={2}>
                      This could be for a deposit or to reduce the amount you
                      borrow. It will help find you a more accurate quote. You
                      can change this amount later.
                    </Text>
                    <Field name="deposit">
                      {({ field }) => (
                        <FormControl id="deposit">
                          <StyledCurrencyInput
                            {...field}
                            id="deposit"
                            pattern="\d*"
                            onValueChange={({ value }) => {
                              field.onChange(value);
                            }}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  {/* {despositAnswer === 1 && ( */}
                  <Box p={4} background="#DCEDFF" fontSize={13} mb={4}>
                    Based on the upfront payment you’ll be looking to borrow{" "}
                    <strong>
                      £
                      {carPrice - Number(values.deposit.replace(/,/g, "")) > 0
                        ? carPrice - Number(values.deposit.replace(/,/g, ""))
                        : 0}
                    </strong>
                  </Box>
                  {/* )} */}
                </>
                {/* )} */}
                {/* {(despositAnswer === 2 || despositAnswer === 3) && (
                  <>
                    <Stack spacing={2}>
                      <Heading size="sm" lineHeight="1.8">
                        How much is the car you’re trading in worth?
                      </Heading>
                      <Text fontSize={13} lineHeight="1.8">
                        This is important to get an accurate quote.
                        Underestimate the amount, just in case you don't get as
                        much as you'd like.
                      </Text>
                      <StyledLink mb={3} fontSize={13} lineHeight="1.8">
                        Help me calculate the trade-in value
                      </StyledLink>
                      <Field name="tradeIn">
                        {({ field }) => (
                          <FormControl id="tradeIn">
                            <StyledCurrencyInput
                              {...field}
                              id="tradeIn"
                              onValueChange={({ value }) => {
                                field.onChange(value);
                              }}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                    {despositAnswer === 2 && (
                      <Box p={4} background="#DCEDFF" fontSize={13} mb={4}>
                        Based on the trade-in value, you’ll be looking to borrow{" "}
                        <strong>
                          £
                          {carPrice - Number(values.tradeIn.replace(/,/g, "")) >
                          0
                            ? carPrice -
                              Number(values.tradeIn.replace(/,/g, ""))
                            : 0}
                        </strong>
                      </Box>
                    )}
                  </>
                )} */}
                {/* {despositAnswer === 3 && (
                  <Box p={4} background="#DCEDFF" fontSize={13} mb={4}>
                    Based on the trade-in value of your car and upfront payment,
                    you’ll be looking to borrow{" "}
                    <strong>
                      £
                      {carPrice -
                        (Number(values.deposit.replace(/,/g, "")) +
                          Number(values.tradeIn.replace(/,/g, ""))) >
                      0
                        ? carPrice -
                          (Number(values.deposit.replace(/,/g, "")) +
                            Number(values.tradeIn.replace(/,/g, "")))
                        : 0}
                    </strong>
                  </Box>
                )} */}
              </Stack>
            </Form>

            <Box>
              <Link
                as={RouterLink}
                to={`/mileage/${fName}/${lName}/${carReg}/${carPrice}/${
                  Number(values.deposit.replace(/,/g, "")) +
                  Number(values.tradeIn.replace(/,/g, ""))
                }`}
              >
                <Button
                  variant="solid"
                  colorScheme="blue"
                  size="lg"
                  isDisabled={!isValid || !dirty}
                >
                  Next — Your mileage
                </Button>
              </Link>
            </Box>
          </Stack>
        );
      }}
    </Formik>
  );
}

export default DepositView;
