import { useState, Fragment } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Checkbox,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  FormControl,
  FormErrorMessage,
  Link,
  Grid,
  Spacer,
  Wrap,
  WrapItem,
  Image
} from "@chakra-ui/react";
import { PlusSquareIcon, CheckIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import NumberFormat from "react-number-format";

import FINAL_RESULTS from "../data/finalResults";
import StyledCurrencyInput from "../components/StyledCurrencyInput";
import StyledInput from "../components/StyledInput";

import zutoLogo from "../images/zuto-logo.png";
import aa from "../images/aa.png";
import amigo from "../images/amigo.png";
import barclays from "../images/barclays.png";
import blackhorse from "../images/blackhorse.png";
import cf247 from "../images/cf247.png";
import cl4u from "../images/cl4u.png";
import creditplus from "../images/creditplus.png";
import paragon from "../images/paragon.png";
import santander from "../images/santander.png";
import select from "../images/select.png";

const FINANCE_TYPES = ["PCP", "HP", "LEASE", "LOAN"];
const LOAN_LENGTH_OPTIONS = [1, 2, 3, 4, 5];

const logoMap = {
  Zuto: zutoLogo,
  CreditPlus: creditplus,
  Paragon: paragon,
  "AA Loans": aa,
  "Select Leasing UK": select,
  "Car Finance 247": cf247,
  "Car Loan 4U": cl4u,
  "Black Horse": blackhorse,
  Barclays: barclays,
  Santander: santander,
  Amigo: amigo
};

function ResultsView() {
  const suitableFinanceTypes = JSON.parse(
    localStorage.getItem("suitableFinanceTypes")
  );

  const {
    carReg,
    carPrice,
    depositValue,
    carMileage,
    budget,
    loanLength,
    firstName
  } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isCompareOpen,
    onOpen: onCompareOpen,
    onClose: onCompareClose
  } = useDisclosure();
  const [filters, setFilters] = useState({
    preApproved: false,
    fiveStar: false,
    phoneSupport: false,
    noEarlyRepayment: false
  });

  const [financeTypeFilter, setFinanceTypeFilter] = useState([
    ...suitableFinanceTypes.map(({ financeType }) => financeType)
  ]);
  const [sortBy, setSortBy] = useState("Monthly payments");
  const [_loanLength, setLoanLength] = useState(Number(loanLength));

  const filterFunc = (result) => {
    if (
      !filters.preApproved &&
      !filters.fiveStar &&
      !filters.phoneSupport &&
      !filters.noEarlyRepayment
    ) {
      return true;
    }
    if (
      filters.preApproved &&
      filters.fiveStar &&
      filters.phoneSupport &&
      filters.noEarlyRepayment
    ) {
      return (
        result.eligibility > 90 &&
        result.customerRating === 5 &&
        result.phoneSupport &&
        result.noEarlyRepayment
      );
    }
    if (filters.preApproved && filters.fiveStar && filters.phoneSupport) {
      return (
        result.eligibility > 89 &&
        result.customerRating === 5 &&
        result.phoneSupport
      );
    }
    if (filters.preApproved && filters.fiveStar) {
      return result.eligibility > 89 && result.customerRating === 5;
    }
    if (filters.preApproved && filters.phoneSupport) {
      return result.eligibility > 89 && result.phoneSupport;
    }
    if (filters.preApproved && filters.noEarlyRepayment) {
      return result.eligibility > 89 && result.noEarlyRepayment;
    }
    if (filters.fiveStar && filters.phoneSupport) {
      return result.customerRating === 5 && result.phoneSupport;
    }
    if (filters.fiveStar && filters.noEarlyRepayment) {
      return result.customerRating === 5 && result.noEarlyRepayment;
    }
    if (filters.phoneSupport && filters.noEarlyRepayment) {
      return result.phoneSupport && result.noEarlyRepayment;
    }
    if (filters.preApproved) {
      return result.eligibility > 89;
    }
    if (filters.phoneSupport) {
      return result.phoneSupport;
    }
    if (filters.fiveStar) {
      return result.customerRating === 5;
    }
    if (filters.noEarlyRepayment) {
      return result.noEarlyRepayment;
    }
  };

  const filteredResults = FINAL_RESULTS.filter(filterFunc).filter((result) => {
    return financeTypeFilter.includes(result.type.toUpperCase());
  });

  const filteredSortedResults = (values) =>
    filteredResults.sort((a, b) => {
      if (sortBy === "Monthly payments") {
        return (
          calcFinanceTypes(a.type, a.apr, values).monthlyRepayments -
          calcFinanceTypes(b.type, b.apr, values).monthlyRepayments
        );
      }
      if (sortBy === "Total amounts") {
        return (
          calcFinanceTypes(a.type, a.apr, values).totalCost -
          calcFinanceTypes(b.type, b.apr, values).totalCost
        );
      }
      return a.companyName - b.companyName;
    });

  const calcFinanceTypes = (type, apr, { carPrice, depositValue }) => {
    const loanAmount = carPrice - Number(depositValue.replace(/,/g, ""));
    const totalCost = loanAmount * Math.pow(1 + apr / 12, 12 * _loanLength);
    const interestTotal = totalCost - loanAmount;
    const balloon = carPrice * 0.9 - (carPrice / 100) * (_loanLength * 12);
    if (type === "HP" || type === "Loan") {
      return {
        totalCost,
        interestTotal,
        monthlyRepayments: totalCost / (12 * _loanLength)
      };
    }
    if (type === "PCP") {
      return {
        totalCost,
        interestTotal,
        balloon,
        monthlyRepayments: (totalCost - balloon) / (12 * _loanLength)
      };
    }
    if (type === "Lease") {
      return {
        totalCost,
        interestTotal,
        monthlyRepayments: (totalCost - balloon) / (12 * _loanLength),
        newTotal: totalCost - balloon
      };
    }
  };

  const unsuitableFinanceTypes = financeTypeFilter.reduce((acc, item) => {
    const _suitableFinanceTypes = suitableFinanceTypes.map(
      ({ financeType }) => financeType
    );
    if (!_suitableFinanceTypes.includes(item)) {
      acc.push(item);
    }
    return acc;
  }, []);

  return (
    <>
      <Formik initialValues={{ carPrice, depositValue, carMileage }}>
        {({ values }) => (
          <>
            <Stack flex="1 0 auto">
              <Box bg="#163F6D" color="white" px={4} pt={2} pb={6}>
                <Stack
                  spacing={4}
                  align="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <Heading size="md" width="80%">
                    {firstName}, we found {FINAL_RESULTS.length} finance results
                    for you
                  </Heading>
                  <Text fontSize="14px" width="80%" mb={2}>
                    Based on borrowing{" "}
                    <Text as="span" color="#FACB52">
                      <NumberFormat
                        value={carPrice - values.depositValue}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"£"}
                      />
                    </Text>{" "}
                    over{" "}
                    <Text
                      as="span"
                      color="#FACB52"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {_loanLength} year
                      {_loanLength > 1 && "s"}
                    </Text>{" "}
                    with a deposit of{" "}
                    <Text as="span" color="#FACB52">
                      <NumberFormat
                        value={values.depositValue}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"£"}
                      />
                    </Text>{" "}
                    for a{" "}
                    <Text
                      as="span"
                      color="#FACB52"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      2015 Ford Focus ({carReg.toUpperCase()})
                    </Text>
                  </Text>
                  <Button
                    variant="outline"
                    colorScheme="white"
                    width="250px"
                    onClick={onOpen}
                  >
                    Update your details
                  </Button>
                  <Modal
                    motionPreset="slideInBottom"
                    onClose={onClose}
                    isOpen={isOpen}
                  >
                    <ModalOverlay />
                    <ModalContent pb={5} maxWidth="350px" mb="200px">
                      <ModalHeader pt="60px">Update your details</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Form autoComplete="off">
                          <Stack spacing={6}>
                            <Box p={4} bg="#D9DBE9">
                              <Heading size="sm" lineHeight="1.5" mb={2}>
                                2015 Ford Focus — {carReg.toUpperCase()} (£
                                {carPrice})
                              </Heading>
                              <Link
                                color="#2969B0"
                                textDecoration="underline"
                                fontSize="13px"
                              >
                                Update the car you’re getting a quote for
                              </Link>
                            </Box>
                            <Stack spacing={2}>
                              <Heading size="sm">Your total deposit</Heading>
                              <Field name="depositValue">
                                {({ field, form }) => (
                                  <FormControl
                                    id="depositValue"
                                    isInvalid={
                                      form.errors.depositValue &&
                                      form.touched.depositValue
                                    }
                                  >
                                    <StyledCurrencyInput
                                      {...field}
                                      pattern="\d*"
                                      id="depositValue"
                                      onValueChange={({ value }) => {
                                        field.onChange(value);
                                      }}
                                    />
                                    <FormErrorMessage>
                                      {form.errors.depositValue}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                            </Stack>
                            <Stack spacing={2}>
                              <Heading size="sm">Length of finance</Heading>
                              <Grid templateColumns="repeat(2,1fr)" gap={2}>
                                {LOAN_LENGTH_OPTIONS.map((opt) => (
                                  <Button
                                    size="lg"
                                    key={opt}
                                    colorScheme="blue"
                                    onClick={() => setLoanLength(opt)}
                                    variant={
                                      opt === _loanLength ? "solid" : "outline"
                                    }
                                  >
                                    {opt} {opt > 1 ? "years" : "year"}
                                  </Button>
                                ))}
                              </Grid>
                            </Stack>
                            <Stack spacing={2}>
                              <Heading size="sm">Annual mileage</Heading>
                              <Field name="carMileage">
                                {({ field, form }) => (
                                  <FormControl
                                    id="carMileage"
                                    isInvalid={
                                      form.errors.carMileage &&
                                      form.touched.carMileage
                                    }
                                  >
                                    <StyledInput
                                      id="carMileage"
                                      type="number"
                                      {...field}
                                      pattern="\d*"
                                    />
                                    <FormErrorMessage>
                                      {form.errors.carMileage}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                            </Stack>
                            <Box pt={6}>
                              <Button
                                onClick={onClose}
                                colorScheme="blue"
                                size="lg"
                              >
                                Update results
                              </Button>
                            </Box>
                          </Stack>
                        </Form>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </Stack>
              </Box>
              <Box
                p={4}
                align="center"
                textAlign="center"
                justifyContent="center"
              >
                <Heading size="sm" mb={4}>
                  Showing you results for:
                </Heading>
                <Wrap mx="auto" justify="center">
                  {FINANCE_TYPES.map((value) => (
                    <WrapItem key={value}>
                      <Button
                        isFullWidth
                        height="34px"
                        borderRadius="17px"
                        colorScheme="blue"
                        onClick={() => {
                          if (financeTypeFilter.includes(value)) {
                            setFinanceTypeFilter(
                              financeTypeFilter.filter((item) => item !== value)
                            );
                            return;
                          } else {
                            setFinanceTypeFilter([
                              ...financeTypeFilter.filter(
                                (item) => item !== value
                              ),
                              value
                            ]);
                          }
                        }}
                        variant={
                          financeTypeFilter.includes(value)
                            ? "solid"
                            : "outline"
                        }
                      >
                        <Box
                          borderRadius="50%"
                          border={"2px solid"}
                          borderColor="blue.500"
                          width="24px"
                          height="24px"
                          mr={4}
                          bg="white"
                        >
                          {financeTypeFilter.includes(value) && (
                            <CheckIcon zIndex={1} color="blue.500" />
                          )}
                        </Box>
                        {value}{" "}
                        {"(" +
                          FINAL_RESULTS.filter(
                            (item) =>
                              item.type.toUpperCase() === value.toUpperCase()
                          ).length +
                          ")"}
                      </Button>
                    </WrapItem>
                  ))}
                </Wrap>
                <Box width="70%" mt={4} mx="auto">
                  <Link textDecoration="underline" fontSize="13px">
                    What are the differences between the finance types?
                  </Link>
                </Box>
              </Box>
              {unsuitableFinanceTypes.length && (
                <Box px={4} pb={8}>
                  <Alert status="warning" variant="left-accent" fontSize="sm">
                    <AlertIcon />
                    Based on your previous answers{" "}
                    {unsuitableFinanceTypes
                      .map((item) => item)
                      .join(" & ")}{" "}
                    might not be suitable for your needs
                  </Alert>
                </Box>
              )}
              <Box borderTop="1px solid #333" borderBottom="1px solid #333">
                <Accordion
                  allowToggle
                  style={{ position: "relative" }}
                  pt="37px"
                  borderBottom="0px"
                  borderTop="0px"
                >
                  <AccordionItem borderBottom="0px" borderTop="0px">
                    <AccordionButton
                      borderBottom="1px solid #333"
                      borderTop="0px"
                      style={{ position: "absolute", top: "0", left: "0" }}
                      width="230px"
                      fontSize="14px"
                      background="#D9DBE9"
                      borderRight="1px solid #333"
                      _expanded={{
                        background: "#2969B0",
                        color: "#FFFFFF",
                        borderBottom: "0px"
                      }}
                    >
                      Sort: {sortBy}
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel
                      borderBottom="0px"
                      borderTop="0px"
                      background="#2969B0"
                      color="#FFFFFF"
                      py={8}
                    >
                      <Stack flex="1 0 auto" spacing={4}>
                        <Checkbox
                          colorScheme="white"
                          onChange={() => {
                            setSortBy(
                              sortBy === "Monthly payments"
                                ? ""
                                : "Monthly payments"
                            );
                          }}
                          isChecked={sortBy === "Monthly payments"}
                        >
                          Monthly payments
                        </Checkbox>
                        <Checkbox
                          iconSize="1.4rem"
                          colorScheme="white"
                          onChange={() => {
                            setSortBy(
                              sortBy === "Total amounts" ? "" : "Total amounts"
                            );
                          }}
                          isChecked={sortBy === "Total amounts"}
                        >
                          Total cost of finance
                        </Checkbox>
                        <Checkbox colorScheme="white">Eligibility</Checkbox>
                        <Checkbox colorScheme="white">APR %</Checkbox>
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem borderBottom="0px" borderTop="0px">
                    <AccordionButton
                      borderBottom="1px solid #333"
                      borderTop="0px"
                      style={{ position: "absolute", top: "0", left: "230px" }}
                      width="100%"
                      fontSize="14px"
                      background="#D9DBE9"
                      _expanded={{
                        background: "#2969B0",
                        color: "#FFFFFF",
                        borderBottom: "0px"
                      }}
                    >
                      <Box flex="1" textAlign="left">
                        Filter results
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel
                      borderBottom="0px"
                      borderTop="0px"
                      background="#2969B0"
                      color="#FFFFFF"
                      py={8}
                    >
                      <Stack flex="1 0 auto" spacing={4}>
                        <Heading size="sm">Only show finance with…</Heading>
                        <Checkbox
                          onChange={() =>
                            setFilters({
                              ...filters,
                              preApproved: !filters.preApproved
                            })
                          }
                          value={filters.preApproved}
                        >
                          90% approval eligibility
                        </Checkbox>
                        <Checkbox
                          onChange={() =>
                            setFilters({
                              ...filters,
                              fiveStar: !filters.fiveStar
                            })
                          }
                          value={filters.fiveStar}
                        >
                          5-star customer ratings
                        </Checkbox>
                        <Checkbox
                          onChange={() =>
                            setFilters({
                              ...filters,
                              phoneSupport: !filters.phoneSupport
                            })
                          }
                          value={filters.phoneSupport}
                        >
                          Phone Support
                        </Checkbox>
                        <Checkbox
                          onChange={() =>
                            setFilters({
                              ...filters,
                              noEarlyRepayment: !filters.noEarlyRepayment
                            })
                          }
                          value={filters.noEarlyRepayment}
                        >
                          No early repayment charges
                        </Checkbox>
                        <Box pt={2}>
                          <Heading size="sm">
                            Showing {filteredResults.length} of{" "}
                            {FINAL_RESULTS.length} Results
                          </Heading>
                        </Box>
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            </Stack>
            <Stack
              py={10}
              px={4}
              spacing={8}
              flex="1 0 auto"
              background="#EFF0F6"
              marginBottom="-48px"
            >
              {filteredSortedResults(values).map(
                ({ id, companyName, type, apr, eligibility }, index) => (
                  <Fragment key={id}>
                    <Stack spacing={4} pb={8} borderBottom="1px solid #A0A3BD">
                      <Flex>
                        <Text fontWeight="bold" fontSize="13px">
                          {companyName} ({type})
                        </Text>
                        <Spacer />
                        <Text fontSize="13px">
                          Eligibility - {eligibility}%
                        </Text>
                      </Flex>
                      <HStack
                        overflowX="auto"
                        style={{ scrollbarWidth: "none" }}
                        background="#ffffff"
                        boxShadow="md"
                        mr="-16px"
                      >
                        {logoMap[companyName] && (
                          <Box
                            p={4}
                            pt={7}
                            textAlign="center"
                            minWidth="40%"
                            alignSelf="stretch"
                            borderRight="1px solid #ccc"
                          >
                            <Image src={logoMap[companyName]} />
                          </Box>
                        )}
                        {type === "Lease" && (
                          <Box
                            p={4}
                            textAlign="center"
                            minWidth="40%"
                            alignSelf="stretch"
                            borderRight="1px solid #ccc"
                          >
                            <Stack>
                              <Text
                                fontSize="14px"
                                fontWeight="bold"
                                color="#2969B0"
                              >
                                Reminder:
                              </Text>
                              <Text fontSize="12px">
                                There's no option to own the car with a lease
                                deal
                              </Text>
                            </Stack>
                          </Box>
                        )}
                        <Box
                          p={4}
                          textAlign="center"
                          minWidth="40%"
                          alignSelf="stretch"
                          borderRight="1px solid #ccc"
                        >
                          <Stack>
                            <Text fontSize="12px">Monthly Repayments</Text>
                            <Text
                              fontSize="20px"
                              fontWeight="bold"
                              color="#2969B0"
                            >
                              <NumberFormat
                                value={calcFinanceTypes(
                                  type,
                                  apr,
                                  values
                                ).monthlyRepayments.toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"£"}
                              />
                            </Text>
                            <Text fontSize="12px">
                              For {_loanLength}{" "}
                              {_loanLength > 1 ? "years" : "year"}
                            </Text>
                          </Stack>
                        </Box>
                        {type === "PCP" && (
                          <Box
                            p={4}
                            textAlign="center"
                            minWidth="40%"
                            alignSelf="stretch"
                            borderRight="1px solid #ccc"
                          >
                            <Stack>
                              <Text fontSize="12px">
                                Optional Final Payment
                              </Text>
                              <Text
                                fontSize="20px"
                                fontWeight="bold"
                                color="#2969B0"
                              >
                                <NumberFormat
                                  value={calcFinanceTypes(
                                    type,
                                    apr,
                                    values
                                  ).balloon.toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"£"}
                                />
                              </Text>
                            </Stack>
                          </Box>
                        )}
                        {type !== "Lease" && (
                          <Box
                            p={4}
                            textAlign="center"
                            minWidth="40%"
                            alignSelf="stretch"
                            borderRight="1px solid #ccc"
                          >
                            <Stack>
                              <Text fontSize="12px">Total amount payable</Text>
                              <Text
                                fontSize="20px"
                                fontWeight="bold"
                                color="#2969B0"
                              >
                                <NumberFormat
                                  value={calcFinanceTypes(
                                    type,
                                    apr,
                                    values
                                  ).totalCost.toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"£"}
                                />
                              </Text>
                              <Text fontSize="12px">
                                on a{" "}
                                <NumberFormat
                                  value={carPrice - values.depositValue}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"£"}
                                />{" "}
                                loan
                              </Text>
                            </Stack>
                          </Box>
                        )}
                        {type === "Lease" && (
                          <Box
                            p={4}
                            textAlign="center"
                            minWidth="40%"
                            alignSelf="stretch"
                            borderRight="1px solid #ccc"
                          >
                            <Stack>
                              <Text fontSize="12px" width="60%" mx="auto">
                                Total of payments
                              </Text>
                              <Text
                                fontSize="20px"
                                fontWeight="bold"
                                color="#2969B0"
                              >
                                <NumberFormat
                                  value={calcFinanceTypes(
                                    type,
                                    apr,
                                    values
                                  ).newTotal.toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"£"}
                                />
                              </Text>
                              <Text fontSize="12px">
                                For a {_loanLength} year lease
                              </Text>
                            </Stack>
                          </Box>
                        )}

                        <Box
                          p={4}
                          textAlign="center"
                          minWidth="40%"
                          alignSelf="stretch"
                          borderRight="1px solid #ccc"
                        >
                          <Stack>
                            <Text fontSize="12px">Total charge for credit</Text>
                            <Text
                              fontSize="20px"
                              fontWeight="bold"
                              color="#2969B0"
                            >
                              <NumberFormat
                                value={calcFinanceTypes(
                                  type,
                                  apr,
                                  values
                                ).interestTotal.toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"£"}
                              />
                            </Text>
                            <Text fontSize="12px">After full loan term</Text>
                          </Stack>
                        </Box>
                        <Box
                          p={4}
                          textAlign="center"
                          minWidth="40%"
                          alignSelf="stretch"
                          borderRight="1px solid #ccc"
                        >
                          <Stack>
                            <Text fontSize="12px">
                              APR
                              <br />
                              Guaranteed
                            </Text>
                            <Text
                              fontSize="20px"
                              fontWeight="bold"
                              color="#2969B0"
                            >
                              {(apr * 100).toFixed(1)}% APR
                            </Text>
                            <Text fontSize="12px">Fixed</Text>
                          </Stack>
                        </Box>

                        <Box
                          p={4}
                          textAlign="center"
                          minWidth="40%"
                          alignSelf="stretch"
                        >
                          <Stack align="center">
                            <Text fontSize="12px" width="80px">
                              {type === "Loan"
                                ? "Upfront payment"
                                : "Deposit amount"}
                            </Text>
                            <Text
                              fontSize="20px"
                              fontWeight="bold"
                              color="#2969B0"
                            >
                              <NumberFormat
                                value={values.depositValue}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"£"}
                              />
                            </Text>
                          </Stack>
                        </Box>
                      </HStack>
                      <Flex>
                        <Button
                          variant="outline"
                          colorScheme="blue"
                          width="160px"
                          rightIcon={<PlusSquareIcon />}
                          onClick={onCompareOpen}
                        >
                          <Link>Compare</Link>
                        </Button>
                        <Spacer />
                        <Button
                          variant="solid"
                          colorScheme="blue"
                          width="160px"
                        >
                          <Link
                            as={RouterLink}
                            to={`/more-details/${id}/${carReg}/${carPrice}/${values.depositValue.replace(
                              /,/g,
                              ""
                            )}/${
                              values.carMileage
                            }/${budget}/${_loanLength}/${firstName}/${calcFinanceTypes(
                              type,
                              apr,
                              values
                            ).totalCost.toFixed(2)}/${calcFinanceTypes(
                              type,
                              apr,
                              values
                            ).monthlyRepayments.toFixed(2)}/${calcFinanceTypes(
                              type,
                              apr,
                              values
                            ).interestTotal.toFixed(2)}/${
                              type === "PCP"
                                ? calcFinanceTypes(
                                    type,
                                    apr,
                                    values
                                  ).balloon.toFixed(2)
                                : ""
                            }`}
                          >
                            More details
                          </Link>
                        </Button>
                      </Flex>
                    </Stack>
                    {index === 1 && (
                      <Box>
                        <Box
                          mx="-16px"
                          mt="-33px"
                          bg="#163F6D"
                          color="#ffffff"
                          py={4}
                          px={4}
                        >
                          <Box>
                            We know choosing the right finance offer can be
                            confusing. Would you like to talk over the phone?
                            <HStack mt={4}>
                              <Text textDecoration="underline" fontSize="12px">
                                Learn the differences between finance types
                              </Text>
                              <Spacer />
                              <Button
                                size="sm"
                                variant="outline"
                                colorScheme="White"
                                width="280px"
                              >
                                Arrange a callback
                              </Button>
                            </HStack>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Fragment>
                )
              )}
            </Stack>
          </>
        )}
      </Formik>
      <Modal
        motionPreset="slideInBottom"
        onClose={onCompareClose}
        isOpen={isCompareOpen}
      >
        <ModalOverlay />
        <ModalContent pb={5} maxWidth="350px" mb="200px">
          <ModalHeader pt="60px">Unavailable in prototype</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading size="sm" mb={4}>
              How this will work…
            </Heading>
            <Text>
              Users will be able to use the compare feature to select multiple
              deals and compare them side by side in a table view. This would
              make it easier to select the ones that are relevant, but also see
              side by side information like APR.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ResultsView;
