import {
  Box,
  Button,
  Heading,
  Stack,
  HStack,
  Text,
  Link,
  FormControl,
  FormLabel,
  FormErrorMessage
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import StyledInput from "../components/StyledInput";
import StyledSelect from "../components/StyledSelect";
const RELATIONSHIP_OPTIONS = [
  "Please Select",
  "Single",
  "Married",
  "Civil partnership",
  "Divorced",
  "Cohabiting",
  "Widowed",
  "Seperated"
];

function PersonalDetailsView() {
  const {
    fName,
    lName,
    carReg,
    carPrice,
    depositValue,
    carMileage,
    budget,
    loanLength
  } = useParams();

  const validate = (value) => {
    if (!value) return "Please enter/select a value";
  };
  return (
    <Formik initialValues={{ fName, lName }}>
      {({ values, isValid }) => {
        return (
          <>
            <Form autoComplete="off">
              <Stack py={2} px={4} spacing={7} flex="1 0 auto">
                <Text fontSize="12px" style={{ textTransform: "uppercase" }}>
                  Your personal details
                </Text>
                <Text fontSize="13px">
                  To get you accurate quotes from our providers, we'll need some
                  information about you. We won't share it with anyone else.{" "}
                </Text>

                <Field name="fName" validate={validate}>
                  {({ field, form }) => (
                    <FormControl
                      id="fName"
                      isInvalid={form.errors.fName && form.touched.fName}
                    >
                      <FormLabel fontWeight="bold">First name</FormLabel>
                      <StyledInput id="fName" {...field} />
                      <FormErrorMessage>{form.errors.fName}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Stack>
                  <Field name="lName" validate={validate}>
                    {({ field, form }) => (
                      <FormControl
                        id="lName"
                        isInvalid={form.errors.lName && form.touched.lName}
                      >
                        <FormLabel fontWeight="bold">Last name</FormLabel>
                        <StyledInput id="lName" {...field} />
                        <FormErrorMessage>{form.errors.lName}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                <Stack>
                  <Heading size="sm">Date of Birth</Heading>
                  <HStack spacing="12px">
                    <StyledInput
                      textAlign="center"
                      placeholder="DD"
                      type="number"
                      pattern="\d*"
                    />
                    <StyledInput
                      textAlign="center"
                      placeholder="MM"
                      type="number"
                      pattern="\d*"
                    />
                    <StyledInput
                      textAlign="center"
                      placeholder="YYYY"
                      type="number"
                      pattern="\d*"
                    />
                  </HStack>
                </Stack>
                <Stack>
                  <Heading size="sm">Relationship status</Heading>
                  <StyledSelect>
                    {RELATIONSHIP_OPTIONS.map((value) => (
                      <option key={value}>{value}</option>
                    ))}
                  </StyledSelect>
                </Stack>
                <Box>
                  <Link
                    as={RouterLink}
                    to={`/household-details/${carReg}/${carPrice}/${depositValue}/${carMileage}/${budget}/${loanLength}/${values.fName}`}
                  >
                    <Button colorScheme="blue" size="lg" isDisabled={!isValid}>
                      Next - Household details
                    </Button>
                  </Link>
                </Box>
              </Stack>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default PersonalDetailsView;
