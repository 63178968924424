import {
  Button,
  Heading,
  Stack,
  Text,
  Link,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Progress,
  Box
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Formik, Field, Form } from "formik";

import StyledInput from "../components/StyledInput";

function QuickDetailsView() {
  const validate = (value) => {
    if (!value) return "Please enter/select a value";
  };

  return (
    <>
      <Box px={4}>
        <Progress value={0} height="3px" />
      </Box>

      <Formik initialValues={{ fName: "", lName: "" }}>
        {({ values, isValid, dirty }) => {
          return (
            <Form autoComplete="off">
              <Box
                pt={10}
                px={8}
                pb="3rem"
                spacing={7}
                minHeight="calc(100vh - 76px)"
              >
                <Stack flex="1 0 auto">
                  <Heading size="md" mb={4}>
                    A few quick details first
                  </Heading>
                  <Field name="fName" validate={validate}>
                    {({ field, form }) => (
                      <FormControl
                        id="fName"
                        isInvalid={form.errors.fName && form.touched.fName}
                      >
                        <FormLabel fontWeight="bold">First name</FormLabel>
                        <StyledInput id="fName" {...field} />
                        <FormErrorMessage>{form.errors.fName}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Stack>
                    <Field name="lName" validate={validate}>
                      {({ field, form }) => (
                        <FormControl
                          id="lName"
                          isInvalid={form.errors.lName && form.touched.lName}
                        >
                          <FormLabel fontWeight="bold">Last name</FormLabel>
                          <StyledInput id="lName" {...field} />
                          <FormErrorMessage>
                            {form.errors.lName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <Stack pt={4}>
                    <Heading size="sm">Email address</Heading>

                    <Text fontSize="13px" mb={4}>
                      We won't share your email address with anyone else, and
                      will only email you the information pack and your results.
                    </Text>
                    <StyledInput type="email" placeholder="example@email.com" />
                  </Stack>
                </Stack>
                <Box
                  p={6}
                  position="fixed"
                  bottom="0"
                  left="0"
                  width="100%"
                  align="center"
                >
                  <Link
                    as={RouterLink}
                    to={`/quiz-questions/1/${values.fName}/${values.lName}`}
                    maxWidth="350px"
                  >
                    <Button
                      isFullWidth
                      colorScheme="blue"
                      isDisabled={!isValid || !dirty}
                      maxWidth="350px"
                    >
                      Let's go
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default QuickDetailsView;
