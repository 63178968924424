import {
  Button,
  Text,
  Heading,
  Link,
  Box,
  Image,
  Divider,
  Stack
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { ArrowForwardIcon } from "@chakra-ui/icons";

import homeImage1 from "../images/home1.png";
import homeImage2 from "../images/home2.png";
import homeFinMadeSimple from "../images/home-fin-made-simple.svg";
import homeImage3 from "../images/home3.png";

function HomeView() {
  // initialise quizAnswers in local storage
  const quizAnswers = JSON.parse(localStorage.getItem("quizAnswers"));
  if (!quizAnswers || !Object.keys(quizAnswers).length) {
    localStorage.setItem(
      "quizAnswers",
      JSON.stringify({
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0
      })
    );
  }
  return (
    <>
      <Stack bg="#163F6D" color="white" px={4} pt={2} pb={4} flex="1 0 auto">
        <Stack
          direction="column"
          mt={8}
          textAlign="center"
          alignItems="center"
          marginX="auto"
          align="center"
        >
          <Heading
            fontSize={24}
            lineHeight="1.4"
            fontWeight="bold"
            mb={4}
            width="90%"
            mx="auto"
          >
            9 out of 10 car buyers don’t understand their finance options
          </Heading>

          <Text mb={6} px="5%">
            Answer a few questions to find the type of finance that matches your
            requirements. We’ll then search 30 leading providers to find your
            best deals.
          </Text>

          <Link as={RouterLink} to="/quick-details">
            <Button
              colorScheme="blue"
              px={12}
              py={6}
              mb={4}
              lineHeight={0}
              minWidth="250px"
            >
              Let's go
            </Button>
          </Link>
          <Button
            variant="link"
            color="white"
            fontSize="12px"
            my={5}
            rightIcon={<ArrowForwardIcon />}
          >
            Get a previous quote
          </Button>
        </Stack>
      </Stack>
      <Box
        mx="auto"
        width="0"
        height="0"
        borderLeft="30px solid transparent"
        borderRight="30px solid transparent"
        borderTop="30px solid #163F6D"
        mb={0}
      ></Box>
      <Box px={12} pt={9} pb={16}>
        <Stack spacing={8} align="center">
          <Stack spacing={5}>
            <Heading size="md">We’ll put you in control.</Heading>
            <Text>
              From the way deals are presented through to the language used,
              it’s hardly surprising most drivers can’t choose the cheapest car
              finance option. We’re here to change that.
            </Text>
          </Stack>
          <Box>
            <Image src={homeImage1} height="180px" width="auto" />
          </Box>
          <Stack spacing={3}>
            <Stack>
              <Text fontWeight="bold">
                Understand your finance options and which are best for you.
              </Text>
              <Text>
                We explain the differences between each type and why it may be
                the best fit for you
              </Text>
            </Stack>
          </Stack>
          <Box>
            <Image src={homeImage2} height="150px" width="auto" />
          </Box>
          <Stack spacing={3}>
            <Stack>
              <Text fontWeight="bold">
                Then we search and compare all the finance options offered by 30
                different providers.
              </Text>
              <Text>
                This will give you the best suitable deals complete with total
                costs and monthly repayments.
              </Text>
            </Stack>
          </Stack>
          <Box>
            <Image src={homeImage3} height="180px" width="auto" />
          </Box>
          <Stack spacing={3}>
            <Stack>
              <Text fontWeight="bold">Get a handy information pack</Text>
              <Text>
                We can email all the information to you, so you have it to hand
                when talking to dealers and can compare offers.
              </Text>
            </Stack>
          </Stack>
          <Link as={RouterLink} to="/quick-details">
            <Button
              colorScheme="blue"
              px={12}
              py={6}
              lineHeight={0}
              minWidth="250px"
            >
              Let's go
            </Button>
          </Link>
        </Stack>
      </Box>
      <Box px={6} pt={12} pb={24} bg="#EFF0F6" mb={-12}>
        <Link
          as={RouterLink}
          to="/financial-guide"
          _hover={{ textDecoration: "none" }}
        >
          <Box
            bg="white"
            borderRadius={16}
            boxShadow="md"
            border="3px solid #ffffff"
            _hover={{ border: "3px solid #163F6D" }}
          >
            <Image src={homeFinMadeSimple} width="90%" mx="auto" />
            <Divider borderColor="#aaaaaa" />
            <Stack spacing={4} p={6}>
              <Heading size="sm">
                Car finance made simple
                <ArrowForwardIcon ml={1} fontSize={24} />
              </Heading>
              <Text>
                Learn about the differences between all the car finance options
              </Text>
            </Stack>
          </Box>
        </Link>
      </Box>
    </>
  );
}

export default HomeView;
