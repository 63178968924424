const makeQuizResultFeatureList = (quizAnswers) => {
  return Object.entries(quizAnswers).reduce((acc, answer) => {
    const questionNumber = Number(answer[0]);
    const answerValue = answer[1];
    if (questionNumber === 1) {
      if (answerValue === 1) {
        acc.push({
          type: "PCP",
          label: "Lower total costs",
          checked: false
        });
        acc.push({
          type: "HP",
          label: "Lower total costs",
          checked: true
        });
        acc.push({
          type: "LEASE",
          label: "Lower total costs",
          checked: false
        });
        acc.push({
          type: "LOAN",
          label: "Lower total costs",
          checked: true
        });
      }
      if (answerValue === 3) {
        acc.push({
          type: "PCP",
          label: "Lower monthly costs",
          checked: true
        });
        acc.push({
          type: "HP",
          label: "Lower monthly costs",
          checked: false
        });
        acc.push({
          type: "LEASE",
          label: "Lower monthly costs",
          checked: true
        });
        acc.push({
          type: "LOAN",
          label: "Lower monthly costs",
          checked: false
        });
      }
    }
    if (questionNumber === 2) {
      if (answerValue === 4) {
        acc.push({
          type: "PCP",
          label: "No deposit required",
          checked: false
        });
        acc.push({
          type: "HP",
          label: "No deposit required",
          checked: false
        });
        acc.push({
          type: "LEASE",
          label: "No deposit required",
          checked: false
        });
        acc.push({
          type: "LOAN",
          label: "No deposit required",
          checked: true
        });
      }
    }
    if (questionNumber === 3) {
      if (answerValue === 3) {
        acc.push({
          type: "PCP",
          label: "Own on day one",
          checked: false
        });
        acc.push({
          type: "HP",
          label: "Own on day one",
          checked: false
        });
        acc.push({
          type: "LEASE",
          label: "Own on day one",
          checked: false
        });
        acc.push({
          type: "LOAN",
          label: "Own on day one",
          checked: true
        });
      }
    }
    if (questionNumber === 4) {
      if (answerValue === 3) {
        acc.push({
          type: "PCP",
          label: "No mileage limits",
          checked: false
        });
        acc.push({
          type: "HP",
          label: "No mileage limits",
          checked: true
        });
        acc.push({
          type: "LEASE",
          label: "No mileage limits",
          checked: false
        });
        acc.push({
          type: "LOAN",
          label: "No mileage limits",
          checked: true
        });
      }
    }
    if (questionNumber === 5) {
      if (answerValue === 1) {
        acc.push({
          type: "PCP",
          label: "Designed for changing car",
          checked: true
        });
        acc.push({
          type: "HP",
          label: "Designed for changing car",
          checked: false,
          yellow: true
        });
        acc.push({
          type: "LEASE",
          label: "Designed for changing car",
          checked: true
        });
        acc.push({
          type: "LOAN",
          label: "Designed for changing car",
          checked: false,
          yellow: true
        });
      }
      if (answerValue === 3) {
        acc.push({
          type: "PCP",
          label: "Made for keeping your car",
          checked: false,
          yellow: true
        });
        acc.push({
          type: "HP",
          label: "Made for keeping your car",
          checked: false,
          yellow: true
        });
        acc.push({
          type: "LEASE",
          label: "Made for keeping your car",
          checked: false
        });
        acc.push({
          type: "LOAN",
          label: "Made for keeping your car",
          checked: true
        });
      }
    }
    if (questionNumber === 6) {
      if (answerValue === 1) {
        acc.push({
          type: "PCP",
          label: "No damage charges",
          checked: false
        });
        acc.push({
          type: "HP",
          label: "No damage charges",
          checked: true
        });
        acc.push({
          type: "LEASE",
          label: "No damage charges",
          checked: false
        });
        acc.push({
          type: "LOAN",
          label: "No damage charges",
          checked: true
        });
      }
      if (answerValue === 2) {
        acc.push({
          type: "PCP",
          label: "No damage charges",
          checked: false,
          yellow: true
        });
        acc.push({
          type: "HP",
          label: "No damage charges",
          checked: true
        });
        acc.push({
          type: "LEASE",
          label: "No damage charges",
          checked: false,
          yellow: true
        });
        acc.push({
          type: "LOAN",
          label: "No damage charges",
          checked: true
        });
      }
    }
    if (questionNumber === 7) {
      if (answerValue === 2) {
        acc.push({
          type: "PCP",
          label: "Servicing can be included",
          checked: true
        });
        acc.push({
          type: "HP",
          label: "Servicing can be included",
          checked: false,
          yellow: true
        });
        acc.push({
          type: "LEASE",
          label: "Servicing can be included",
          checked: true
        });
        acc.push({
          type: "LOAN",
          label: "Servicing can be included",
          checked: false,
          yellow: true
        });
      }
      if (answerValue === 3) {
        acc.push({
          type: "PCP",
          label: "Servicing can be included",
          checked: false,
          yellow: true
        });
        acc.push({
          type: "HP",
          label: "Servicing can be included",
          checked: false
        });
        acc.push({
          type: "LEASE",
          label: "Servicing can be included",
          checked: true
        });
        acc.push({
          type: "LOAN",
          label: "Servicing can be included",
          checked: false
        });
      }
    }
    if (questionNumber === 8) {
      if (answerValue === 2) {
        acc.push({
          type: "PCP",
          label: "Able to modify straight away",
          checked: false
        });
        acc.push({
          type: "HP",
          label: "Able to modify straight away",
          checked: false
        });
        acc.push({
          type: "LEASE",
          label: "Able to modify straight away",
          checked: false
        });
        acc.push({
          type: "LOAN",
          label: "Able to modify straight away",
          checked: true
        });
      }
    }
    if (questionNumber === 9) {
      if (answerValue === 3) {
        acc.push({
          type: "PCP",
          label: "Okay with lower credit scores",
          checked: true
        });
        acc.push({
          type: "HP",
          label: "Okay with lower credit scores",
          checked: true
        });
        acc.push({
          type: "LEASE",
          label: "Okay with lower credit scores",
          checked: true
        });
        acc.push({
          type: "LOAN",
          label: "Okay with lower credit scores",
          checked: false,
          yellow: true
        });
      }
      if (answerValue === 4) {
        acc.push({
          type: "PCP",
          label: "Okay with lower credit scores",
          checked: false,
          yellow: true
        });
        acc.push({
          type: "HP",
          label: "Okay with lower credit scores",
          checked: false,
          yellow: true
        });
        acc.push({
          type: "LEASE",
          label: "Okay with lower credit scores",
          checked: false,
          yellow: true
        });
        acc.push({
          type: "LOAN",
          label: "Okay with lower credit scores",
          checked: false
        });
      }
    }
    if (questionNumber === 10) {
      if (answerValue === 3) {
        acc.push({
          type: "PCP",
          label: "Cars over 3 years old possible",
          checked: true
        });
        acc.push({
          type: "HP",
          label: "Cars over 3 years old possible",
          checked: true
        });
        acc.push({
          type: "LEASE",
          label: "Cars over 3 years old possible",
          checked: false
        });
        acc.push({
          type: "LOAN",
          label: "Cars over 3 years old possible",
          checked: true
        });
      }
    }

    return acc;
  }, []);
}

export default makeQuizResultFeatureList;