import {
  Heading,
  Stack,
  Text,
  Box,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
  Divider
} from "@chakra-ui/react";

import { ArrowBackIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";

import personalLoanCosts from "../images/personal-loan-costs.svg";
import personalLoanChart1 from "../images/personal-loan-chart-1.svg";
import personalLoanChart2 from "../images/personal-loan-chart-2.svg";
import personalLoanChart3 from "../images/personal-loan-chart-3.svg";

function PersonalLoanGuideView() {
  const history = useHistory();
  return (
    <Stack py={2} px={6} flex="1 0 auto">
      <Stack spacing={6}>
        <Link color="#2969B0" onClick={() => history.goBack()}>
          <ArrowBackIcon />
          Back
        </Link>
        <Heading>Personal Loan</Heading>
        <Heading fontSize={16}>
          A regular loan from a bank or building society instead of specific car
          finance.
        </Heading>
        <Text>
          Suitable for both new and used cars, personal loans are a very popular
          way of raising the funds to buy a car.
          <br />
          <br />
          These are the easiest to understand as they work like any other form
          of credit.
        </Text>
        <Heading fontSize={16}>How it works</Heading>
        <Text>
          Personal loans work the same if you’re borrowing them to buy a car, or
          a new TV - you find the loan you want, apply for it, and use it to buy
          the car.
          <br />
          <br />
          You can use the loan for the whole cost of the car, or you can combine
          a loan with savings to reduce the interest on the amount you borrow.
          <br />
          <br />
          Unlike other forms of car finance, you pay for the whole car upfront,
          this means you own the car straight away. This means there aren’t any
          mileage restrictions, or restrictions on selling the car if you need
          to.
        </Text>
        <Image src={personalLoanCosts} />
        <Accordion allowToggle p={0}>
          <AccordionItem px={0}>
            <AccordionButton py={4} px={0}>
              <Heading color="blue.500" textAlign="left" fontSize={20}>
                See how the numbers are calculated
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel px={0}>
              <Stack spacing={5}>
                <Stack spacing={2}>
                  <Heading>1. Savings / cash</Heading>
                  <Text>
                    Instead of borrowing the whole amount, you can combine a
                    personal loan with your savings or cash to reduce the amount
                    you borrow. There isn’t a set amount you have to do.
                  </Text>
                  <Image src={personalLoanChart1} />
                </Stack>
                <Divider />
                <Stack spacing={2}>
                  <Heading>2. Interest</Heading>
                  <Text>
                    This is the additional amount you pay on top of how much you
                    borrow. The longer the length of deal and larger the amount
                    you’re borrowing the more you’ll pay in interest.
                  </Text>
                  <Image src={personalLoanChart2} />
                </Stack>
                <Stack spacing={2}>
                  <Heading>3. Monthly Payments</Heading>
                  <Text>
                    Monthly payment are calculated by taking the amount you’re
                    borrowing and the interest and dividing by the length of the
                    contract. The longer the length of deal, the smaller the
                    monthly payments end up being.
                  </Text>
                  <Image src={personalLoanChart3} />
                </Stack>
                <Stack spacing={2}>
                  <Heading>4. End of the contract</Heading>
                  <Text>
                    After the final payment is made, the car is yours. You can
                    then choose whether you keep it, sell it, or part-exchange
                    it for a new car or deal.
                  </Text>
                  <Image src={personalLoanChart3} />
                </Stack>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Box px={6} py={4} bg="#F7F7FC" borderRadius={16} my={3}>
          <Heading fontSize={16} mb={4} lineHeight="24px">
            A few more things to know about Personal Loans
          </Heading>
          <Text>
            > Depending on your credit score, and the deals available, the costs
            may be higher than other car finance types because they aren’t
            secured against the car.
            <br />
            <br />
            > It can take longer sometimes to get the car as you have to wait
            for the money to appear in your bank before you can buy it.
            <br />
            <br />
            > Many people can’t get the advertised rates for a personal loan,
            with only 51% of people getting what is advertised.
            <br />
            <br />
            > You can use the loan to cover other costs such as insurance or
            servicing, but remember that the more you borrow, the more interest
            you will likely pay
            <br />
            <br />> Some comparisons between car finance and personal loans
            don’t make it clear whether you are borrowing the whole amount, or
            whether you’re including savings in the purchase.
          </Text>
        </Box>
      </Stack>
    </Stack>
  );
}

export default PersonalLoanGuideView;
