import {
  Button,
  Box,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
  Link,
  HStack,
  Image,
  FormErrorMessage,
  FormControl
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";

import StyledInput from "../components/StyledInput";

import regInputGB from "../images/reg-input-gb.svg";
import car from "../images/car.svg";
import StyledLink from "../components/StyledLink";
import StyledCurrencyInput from "../components/StyledCurrencyInput";

function RegResultsView() {
  const quizAnswers = JSON.parse(localStorage.getItem("quizAnswers"));
  const depositAnswer = quizAnswers[2];

  const { fName, lName, carReg } = useParams();
  const validate = (value) => {
    if (!value) return "Please enter/select a value";
  };
  return (
    <Formik initialValues={{ carPrice: "" }}>
      {({ values, isValid, dirty }) => (
        <Form autoComplete="off">
          <Stack spacing={4} py={2} px={4} flex="1 0 auto">
            <Text fontSize="12px" style={{ textTransform: "uppercase" }}>
              About the car
            </Text>
            <Stack spacing={7} py={0} px={0} flex="1 0 auto">
              <HStack justifyContent="space-between" marginTop="-10px">
                <Heading as="h2" size="xs">
                  2015 Ford Focus
                </Heading>
                <InputGroup width="auto" transform="scale(0.8)">
                  <InputLeftAddon
                    borderRadius="8px"
                    background="#1E438B"
                    overflow="hidden"
                    px="3px"
                    marginTop="-1px"
                    height="42px"
                  >
                    <Image transform="scale(0.8)" src={regInputGB} />
                  </InputLeftAddon>
                  <Input
                    borderRadius="8px"
                    value={carReg}
                    width={120}
                    borderColor="#1E438B"
                    borderWidth="2px"
                    fontWeight="bold"
                    textTransform="uppercase"
                    onChange={() => false}
                  />
                </InputGroup>
              </HStack>
              <Image src={car} />
              <Box
                background="#FEEBC8"
                fontSize={13}
                alignItems="left"
                flexDirection="column"
                p="20px"
              >
                <Text mb="6px">
                  Based on our checks, this car should cost between{" "}
                  <strong>£6,000 - £8,800</strong>.
                </Text>
                <StyledLink>See how we worked this out</StyledLink>
              </Box>
              <Stack>
                <Heading size="sm">What's the price of the car?</Heading>
                <Field name="carPrice" validate={validate}>
                  {({ field, form }) => {
                    return (
                      <FormControl
                        id="carPrice"
                        isInvalid={
                          form.errors.carPrice && form.touched.carPrice
                        }
                      >
                        <StyledCurrencyInput {...field} pattern="\d*" />
                        <FormErrorMessage>
                          {form.errors.carPrice}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
              </Stack>
              <Stack>
                <Heading size="sm">What's its current mileage?</Heading>
                <StyledInput type="number" pattern="\d*" />
              </Stack>
              {depositAnswer === 4 && (
                <Link
                  as={RouterLink}
                  to={`/mileage/${fName}/${lName}/${carReg}/${values.carPrice.replace(
                    /,/g,
                    ""
                  )}/0`}
                >
                  <Button
                    colorScheme="blue"
                    size="lg"
                    isDisabled={!isValid || !dirty}
                  >
                    Next - Your mileage
                  </Button>
                </Link>
              )}
              {depositAnswer !== 4 && (
                <Link
                  as={RouterLink}
                  to={`/deposit/${fName}/${lName}/${carReg}/${values.carPrice.replace(
                    /,/g,
                    ""
                  )}`}
                >
                  <Button
                    colorScheme="blue"
                    size="lg"
                    isDisabled={!isValid || !dirty}
                  >
                    Next - Your deposit
                  </Button>
                </Link>
              )}
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

export default RegResultsView;
