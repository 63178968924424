import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  Link,
  FormErrorMessage,
  FormControl
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import StyledCurrencyInput from "../components/StyledCurrencyInput";
import StyledLink from "../components/StyledLink";

function BudgetView() {
  const {
    fName,
    lName,
    carReg,
    carPrice,
    depositValue,
    carMileage
  } = useParams();

  const validate = (value) => {
    if (!value) return "Please enter/select a value";
  };

  return (
    <Formik initialValues={{ budget: "" }}>
      {({ values, isValid, dirty }) => (
        <Stack spacing={7} py={2} px={4} flex="1 0 auto" minHeight="500px">
          <Text fontSize="12px" style={{ textTransform: "uppercase" }}>
            Finance details
          </Text>
          <Stack>
            <Heading size="sm" lineHeight="1.8">
              What's your monthly budget for repayments?
            </Heading>
            <Text fontSize={13} lineHeight="1.8" mb={2}>
              This will help make sure that your deals are affordable.
            </Text>
            <StyledLink fontSize={13} mb={4}>
              Help check my budget is actually affordable
            </StyledLink>
            <Form autoComplete="off">
              <Field name="budget" validate={validate}>
                {({ field, form }) => (
                  <FormControl
                    id="budget"
                    isInvalid={form.errors.budget && form.touched.budget}
                  >
                    <StyledCurrencyInput
                      id="budget"
                      {...field}
                      placeholder="e.g. 200"
                      pattern="\d*"
                      onValueChange={({ value }) => {
                        field.onChange(value);
                      }}
                    />
                    <FormErrorMessage>{form.errors.budget}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Form>
          </Stack>
          <Box>
            <Link
              as={RouterLink}
              to={`/loan-length/${fName}/${lName}/${carReg}/${carPrice}/${depositValue}/${carMileage}/${values.budget.replace(
                /,/g,
                ""
              )}`}
            >
              <Button
                colorScheme="blue"
                size="lg"
                isDisabled={!isValid || !dirty}
              >
                Next - Length of deal
              </Button>
            </Link>
          </Box>
        </Stack>
      )}
    </Formik>
  );
}

export default BudgetView;
