import {
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Button,
  Heading,
  useDisclosure,
  Stack,
  Link,
  Flex,
  Text,
  Box,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  UnorderedList,
  ListItem
} from "@chakra-ui/react";
import { useParams, useHistory } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";

import FINAL_RESULTS from "../data/finalResults";
import NumberFormat from "react-number-format";

function ResultsMoreDetailsView() {
  const {
    id,
    carReg,
    carPrice,
    depositValue,
    carMileage,
    budget,
    loanLength,
    firstName,
    totalAmount,
    monthlyPayment,
    interest,
    balloon
  } = useParams();
  const companyDetails = FINAL_RESULTS.filter(
    (result) => result.id === Number(id)
  )[0];
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Stack
        background="#163F6D"
        color="#ffffff"
        spacing={4}
        flex="1 0 auto"
        px={4}
        pb={8}
      >
        <Box>
          <Link
            color="#ffffff"
            onClick={() =>
              history.push(
                `/results/${carReg}/${carPrice}/${depositValue}/${carMileage}/${budget}/${loanLength}/${firstName}/`
              )
            }
          >
            <ArrowBackIcon />
            Back
          </Link>
        </Box>
        <Box pb={4} textAlign="center">
          <Heading size="md">
            {companyDetails.companyName} — {companyDetails.type} deal
          </Heading>
          <HStack mt={4} justify="center">
            <Text lineHeight="20px">
              Eligibility - {companyDetails.eligibility}%
            </Text>
            <Text fontSize="12px" lineHeight="20px">
              (Subject to vehicle & ID checks)
            </Text>
          </HStack>
        </Box>
        <Stack p={6} background="#FFFFFF" color="#1A202C" flex="1 0 auto">
          <Box>
            <Flex justifyContent="space-between">
              <Text color="#2969B0">{loanLength * 12} Monthly payments</Text>
              <Text>
                <NumberFormat
                  value={monthlyPayment}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"£"}
                />
              </Text>
            </Flex>
          </Box>

          {balloon && (
            <Box>
              <Flex justifyContent="space-between">
                <Text color="#2969B0">Optional Final Payment</Text>
                <Text>
                  <NumberFormat
                    value={balloon}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"£"}
                  />
                </Text>
              </Flex>
            </Box>
          )}

          <Box>
            <Flex justifyContent="space-between">
              <Text color="#2969B0">Total amount payable</Text>
              <Text>
                <NumberFormat
                  value={totalAmount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"£"}
                />
              </Text>
            </Flex>
          </Box>

          <Box>
            <Flex justifyContent="space-between">
              <Text color="#2969B0">Interest on finance</Text>
              <Text>
                <NumberFormat
                  value={interest}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"£"}
                />
              </Text>
            </Flex>
          </Box>

          <Box>
            <Flex justifyContent="space-between">
              <Text color="#2969B0">Representative rate</Text>
              <Text>{(companyDetails.apr * 100).toFixed(1)}% APR</Text>
            </Flex>
          </Box>

          <Box>
            <Flex justifyContent="space-between">
              <Text color="#2969B0">Deposit amount</Text>
              <Text>
                <NumberFormat
                  value={depositValue}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"£"}
                />
              </Text>
            </Flex>
          </Box>
        </Stack>
      </Stack>
      <Stack p={4} mt={6} flex="1 0 auto">
        <Accordion allowToggle allowMultiple>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                About this finance deal
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>(Unavailable in prototype)</AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                Things to remember about {companyDetails.type}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>(Unavailable in prototype)</AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                Fees & Charges
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>(Unavailable in prototype)</AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                About this provider
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>(Unavailable in prototype)</AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                Next steps
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>(Unavailable in prototype)</AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Stack>

      <Box p={4} align="center">
        <Button
          size="lg"
          colorScheme="blue"
          width="270px"
          mb={4}
          onClick={onOpen}
        >
          Go to provider
        </Button>
        {companyDetails.type === "HP" && (
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxWidth="350px" mb="200px">
              <ModalHeader mt={8}>
                Before getting Hire Purchase finance
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Box>
                  Whilst Hire Purchase deals are right for some people, they
                  aren't right for everyone. Just to check you've understood
                  some of the key points:
                  <UnorderedList pl={2} pt={4} spacing={2}>
                    <ListItem>
                      You cannot sell the vehicle without settling the finance.
                    </ListItem>
                    <ListItem>
                      You will not own the vehicle until you have made all of
                      the repayments necessary.
                    </ListItem>
                    <ListItem>
                      Hire Purchase deals won't have mileage limits unlike other
                      forms of finance such as PCP or Leasing.
                    </ListItem>
                    <ListItem>
                      The monthly payments can be more expensive then the
                      payments for other finance options, for example PCP, this
                      is because you pay off the full value of your chosen car.
                    </ListItem>
                  </UnorderedList>
                </Box>
                <Stack py={3}>
                  <Box pt={4}>
                    <Button colorScheme="blue" size="md" isFullWidth>
                      I understand, go to provider
                    </Button>
                  </Box>
                  <Box pt={4}>
                    <Button
                      colorScheme="blue"
                      variant="outline"
                      size="md"
                      isFullWidth
                      onClick={() =>
                        history.push(
                          `/results/${carReg}/${carPrice}/${depositValue}/${carMileage}/${budget}/${loanLength}/${firstName}/`
                        )
                      }
                    >
                      Take me back to results
                    </Button>
                  </Box>
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
        {companyDetails.type === "Loan" && (
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxWidth="350px" mb="200px">
              <ModalHeader mt={8}>Before getting a Personal Loan</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Box>
                  Whilst Personal Loan deals are right for some people, they
                  aren't right for everyone. Just to check you've understood
                  some of the key points:
                  <UnorderedList pl={2} pt={4} spacing={2}>
                    <ListItem>
                      The monthly repayments on a personal loan can be higher
                      than other forms of car finance because you aren't using
                      your car as security for the loan.
                    </ListItem>
                    <ListItem>
                      People with lower credit scores may find it harder to get
                      acceptable rates on a personal loan, when they might have
                      more success with other types of finance.
                    </ListItem>
                    <ListItem>
                      You might need to wait for the money to come through,
                      although some lenders make funds available almost
                      immediately.
                    </ListItem>
                    <ListItem>
                      Whilst it can be tempting to borrow more than you need on
                      a personal loan, remember to check that it's affordable
                      before you do.
                    </ListItem>
                  </UnorderedList>
                </Box>
                <Stack py={3}>
                  <Box pt={4}>
                    <Button colorScheme="blue" size="md" isFullWidth>
                      I understand, go to provider
                    </Button>
                  </Box>
                  <Box pt={4}>
                    <Button
                      colorScheme="blue"
                      variant="outline"
                      size="md"
                      isFullWidth
                      onClick={() =>
                        history.push(
                          `/results/${carReg}/${carPrice}/${depositValue}/${carMileage}/${budget}/${loanLength}/${firstName}/`
                        )
                      }
                    >
                      Take me back to results
                    </Button>
                  </Box>
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
        {companyDetails.type === "PCP" && (
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxWidth="350px" mb="200px">
              <ModalHeader mt={8}>
                Before getting Personal Contract Purchase (PCP) finance
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Box>
                  Whilst PCP deals are right for some people, they aren't right
                  for everyone. Just to check you've understood some of the key
                  points:
                  <UnorderedList pl={2} pt={4} spacing={2}>
                    <ListItem>
                      You cannot sell the vehicle without settling the finance.
                    </ListItem>
                    <ListItem>
                      You will not own the vehicle until you have made all of
                      the repayments necessary.
                    </ListItem>
                    <ListItem>
                      You will need to agree to mileage limits before you buy
                      the car. These are used to calculate the value it will be
                      worth when you finish your monthly repayments. This is
                      then set as a final optional payment, or balloon payment,
                      that you can choose to pay, or you can choose to return /
                      trade-in the car to settle the remaining finance.
                    </ListItem>
                    <ListItem>
                      PCP deals can have lower monthly payments because your
                      monthly payments are only paying the difference between
                      the price of the car and what the predicted value is at
                      the end of the deal.
                    </ListItem>
                  </UnorderedList>
                </Box>
                <Stack py={3}>
                  <Box pt={4}>
                    <Button colorScheme="blue" size="md" isFullWidth>
                      I understand, go to provider
                    </Button>
                  </Box>
                  <Box pt={4}>
                    <Button
                      colorScheme="blue"
                      variant="outline"
                      size="md"
                      isFullWidth
                      onClick={() =>
                        history.push(
                          `/results/${carReg}/${carPrice}/${depositValue}/${carMileage}/${budget}/${loanLength}/${firstName}/`
                        )
                      }
                    >
                      Take me back to results
                    </Button>
                  </Box>
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
        {companyDetails.type === "Lease" && (
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxWidth="350px" mb="200px">
              <ModalHeader mt={8}>Before getting a Lease deal</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Box>
                  Whilst Leasing is the right option for some people, it isn't
                  right for everyone. Just to check you've understood some of
                  the key points:
                  <UnorderedList pl={2} pt={4} spacing={2}>
                    <ListItem>
                      You will not have an option to purchase the car at the end
                      of your agreement.
                    </ListItem>
                    <ListItem>
                      You agree to an annual mileage allowance and you will be
                      charged an excess charge if you exceed this.
                    </ListItem>
                    <ListItem>
                      You are tied into the contract for a fixed term just like
                      a mobile contract. If you request to cancel the contract
                      early or make changes, charges may apply.
                    </ListItem>
                    <ListItem>
                      Make sure to keep your vehicle maintained to a good
                      standard. There is a fair wear and tear guide available
                      which outlines what would be considered as expected under
                      normal usage, this shouldn’t be confused with damage which
                      can often result in costly bills. You should familiarise
                      yourself with this at the beginning of your agreed lease
                      to make yourself aware what is acceptable.
                    </ListItem>
                  </UnorderedList>
                </Box>
                <Stack py={3}>
                  <Box pt={4}>
                    <Button colorScheme="blue" size="md" isFullWidth>
                      I understand, go to provider
                    </Button>
                  </Box>
                  <Box pt={4}>
                    <Button
                      colorScheme="blue"
                      variant="outline"
                      size="md"
                      isFullWidth
                      onClick={() =>
                        history.push(
                          `/results/${carReg}/${carPrice}/${depositValue}/${carMileage}/${budget}/${loanLength}/${firstName}/`
                        )
                      }
                    >
                      Take me back to results
                    </Button>
                  </Box>
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
        <Box>
          <Link
            textDecoration="underline"
            onClick={() =>
              history.push(
                `/results/${carReg}/${carPrice}/${depositValue}/${carMileage}/${budget}/${loanLength}/${firstName}/`
              )
            }
          >
            Back to results
          </Link>
        </Box>
      </Box>
    </>
  );
}

export default ResultsMoreDetailsView;
