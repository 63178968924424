import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  Link,
  FormControl,
  FormErrorMessage
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import StyledInput from "../components/StyledInput";

function MileageView() {
  const { fName, lName, carReg, carPrice, depositValue } = useParams();

  const validate = (value) => {
    if (!value) return "Please enter/select a value";
  };
  return (
    <Formik initialValues={{ mileage: "" }}>
      {({ values, isValid, dirty }) => (
        <Stack spacing={7} py={2} px={4} flex="1 0 auto" minHeight="500px">
          <Text fontSize="12px" style={{ textTransform: "uppercase" }}>
            Deposit & Mileage
          </Text>
          <Stack>
            <Heading size="sm" lineHeight="1.8">
              How many miles do you expect to drive in a year?
            </Heading>
            <Text fontSize={13} lineHeight="1.8" mb="10px">
              Try to be as accurate as possible as it can change your repayment
              amounts, and if you exceed the agreed limit result in extra
              charges.
            </Text>
            <Link
              fontSize={13}
              color="#2969B0"
              textDecoration="underline"
              mb={4}
            >
              Help to calculate my mileage
            </Link>
            <Form autoComplete="off">
              <Field name="mileage" validate={validate}>
                {({ field, form }) => (
                  <FormControl
                    id="mileage"
                    isInvalid={form.errors.mileage && form.touched.mileage}
                  >
                    <StyledInput
                      {...field}
                      type="number"
                      placeholder="e.g. 10000"
                      pattern="\d*"
                    />
                    <FormErrorMessage>{form.errors.mileage}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Form>
          </Stack>
          <Box>
            <Link
              as={RouterLink}
              to={`/budget/${fName}/${lName}/${carReg}/${carPrice}/${depositValue}/${values.mileage}`}
            >
              <Button
                colorScheme="blue"
                size="lg"
                isDisabled={!isValid || !dirty}
              >
                Next - Your budget
              </Button>
            </Link>
          </Box>
        </Stack>
      )}
    </Formik>
  );
}

export default MileageView;
