import { InputGroup, InputLeftElement } from "@chakra-ui/react";
import NumberFormat from "react-number-format";
import StyledInput from "./StyledInput";

function StyledCurrencyInput(props) {
  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        color="gray.500"
        fontSize="1.2em"
        children="£"
        style={{ borderRight: "1px solid #999" }}
        height="3rem"
      />
      <NumberFormat
        pl="50px"
        placeholder="e.g. 10000"
        customInput={StyledInput}
        thousandSeparator
        isNumericString
        {...props}
      />
    </InputGroup>
  );
}

export default StyledCurrencyInput;
