import { useState } from "react";
import {
  Box,
  Button,
  Link,
  Text,
  Progress,
  IconButton,
  SlideFade
} from "@chakra-ui/react";
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";

import QUESTION_MAP from "../data/quizQuestions";
import QuizQuestion from "../components/QuizQuestion";
import useWindowDimensions from "../hooks/useWindowDimensions";

function QuizQuestionsView() {
  const [stateChange, setStateChange] = useState(false);
  const history = useHistory();
  const { qNumber, fName, lName } = useParams();
  const { height } = useWindowDimensions();

  const quizAnswers = JSON.parse(localStorage.getItem("quizAnswers"));

  return (
    <>
      <Box
        spacing={0}
        px={4}
        pb={height < 690 ? "3rem" : 0}
        position="relative"
        minHeight="calc(100vh - 72px)"
      >
        <Progress
          value={(Number(qNumber) / Object.keys(QUESTION_MAP).length) * 100}
          height="3px"
        />
        {Number(qNumber) >= 1 && (
          <IconButton
            variant="outline"
            colorScheme="blue"
            border="1px solid #8A8A8A"
            borderRadius="50%"
            textAlign="center"
            width="28px"
            onClick={() => history.goBack()}
            position="absolute"
            top="36px"
            left="24px"
            style={{
              marginTop: 0
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <div id="quizQuestion">
          <QuizQuestion
            {...QUESTION_MAP[qNumber]}
            onAnswerSelect={(value) => {
              localStorage.setItem(
                "quizAnswers",
                JSON.stringify({
                  ...quizAnswers,
                  [qNumber]: QUESTION_MAP[qNumber].options.indexOf(value) + 1
                })
              );
              setStateChange(!stateChange);
            }}
            selectedAnswer={quizAnswers[Number(qNumber)]}
            questionNumber={Number(qNumber)}
          />
          <SlideFade
            in={
              quizAnswers[qNumber] !== 0 &&
              QUESTION_MAP[qNumber].confirmContent[quizAnswers[qNumber] - 1]
            }
          >
            <Text
              background="#DCEDFF"
              px={4}
              py={4}
              fontSize="14px"
              lineHeight="1.6"
              mt={6}
              mb={0}
            >
              {QUESTION_MAP[qNumber].confirmContent[quizAnswers[qNumber] - 1]}
            </Text>
          </SlideFade>
        </div>
        {height < 690 && (
          <Box align="center" p={6}>
            <Link
              as={RouterLink}
              to={
                Number(qNumber) === Object.keys(QUESTION_MAP).length
                  ? `/loading-quiz-results/${fName}/${lName}`
                  : `/quiz-questions/${Number(qNumber) + 1}/${fName}/${lName}`
              }
            >
              {Number(qNumber) === Object.keys(QUESTION_MAP).length && (
                <Button
                  colorScheme="blue"
                  isFullWidth
                  disabled={!quizAnswers[Number(qNumber)]}
                >
                  Get your results
                </Button>
              )}
              {Number(qNumber) !== Object.keys(QUESTION_MAP).length && (
                <Button
                  colorScheme="blue"
                  isFullWidth
                  disabled={!quizAnswers[Number(qNumber)]}
                >
                  Next
                </Button>
              )}
            </Link>
          </Box>
        )}
      </Box>
      {height >= 690 && (
        <Box
          align="center"
          p={6}
          position="fixed"
          width="100%"
          bottom="0"
          left="0"
        >
          <Link
            as={RouterLink}
            to={
              Number(qNumber) === Object.keys(QUESTION_MAP).length
                ? `/loading-quiz-results/${fName}/${lName}`
                : `/quiz-questions/${Number(qNumber) + 1}/${fName}/${lName}`
            }
            maxWidth="350px"
          >
            {Number(qNumber) === Object.keys(QUESTION_MAP).length && (
              <Button
                colorScheme="blue"
                isFullWidth
                maxWidth="350px"
                disabled={!quizAnswers[Number(qNumber)]}
              >
                Get your results
              </Button>
            )}
            {Number(qNumber) !== Object.keys(QUESTION_MAP).length && (
              <Button
                colorScheme="blue"
                isFullWidth
                maxWidth="350px"
                disabled={!quizAnswers[Number(qNumber)]}
              >
                Next
              </Button>
            )}
          </Link>
        </Box>
      )}
    </>
  );
}

export default QuizQuestionsView;
