import { useEffect } from "react";
import { Heading, Stack, Spinner } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";

function LoadingFinalResultsView() {
  const {
    carReg,
    carPrice,
    depositValue,
    carMileage,
    budget,
    loanLength,
    firstName
  } = useParams();

  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push(
        `/results/${carReg}/${carPrice}/${depositValue}/${carMileage}/${budget}/${loanLength}/${firstName}`
      );
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Stack
      spacing={4}
      pt="150px"
      pb="50px"
      px={4}
      flex="1 0 auto"
      align="center"
      position="relative"
      minHeight="calc(100vh - 72px)"
    >
      <Spinner
        thickness="6px"
        speed="1s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
        width="5rem"
        height="5rem"
        mb={4}
      />

      <Heading
        size="md"
        lineHeight="1.4"
        fontSize="24px"
        px="10%"
        textAlign="center"
      >
        Searching our panel for the best deals <br />
        for you
      </Heading>
    </Stack>
  );
}

export default LoadingFinalResultsView;
