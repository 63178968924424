import {
  Heading,
  Stack,
  Text,
  Box,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
  Divider
} from "@chakra-ui/react";

import { ArrowBackIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";

import hirePurchaseCosts from "../images/hire-purchase-costs.svg";
import hirePurchaseChart1 from "../images/hire-purchase-chart-1.svg";
import hirePurchaseChart2 from "../images/hire-purchase-chart-2.svg";
import hirePurchaseChart3 from "../images/hire-purchase-chart-3.svg";

function HirePurchaseGuideView() {
  const history = useHistory();
  return (
    <Stack py={2} px={6} flex="1 0 auto">
      <Stack spacing={6}>
        <Link color="#2969B0" onClick={() => history.goBack()}>
          <ArrowBackIcon />
          Back
        </Link>
        <Heading>Hire Purchase</Heading>
        <Heading fontSize={16}>
          The straightforward car finance option, with a deposit and fixed
          monthly payments
        </Heading>
        <Text>
          This is the most common car finance option for used cars with 58% of
          purchases using Hire Purchase
          <br />
          <br />
          It’s simpler than other finance types, but it’s still worth
          understanding the details before getting any type of finance.
        </Text>
        <Heading fontSize={16}>How it works</Heading>
        <Text>
          Hire Purchase finance is a bit like a normal repayment mortgage. You
          pay a deposit upfront, then pay off the remaining amount in monthly
          repayments.
          <br />
          <br />
          The one difference is that unlike a mortgage, you don’t technically
          own the car until you’ve finished paying everything off.
        </Text>
        <Image src={hirePurchaseCosts} />
        <Accordion allowToggle p={0}>
          <AccordionItem px={0}>
            <AccordionButton py={4} px={0}>
              <Heading color="blue.500" textAlign="left" fontSize={20}>
                See how the numbers are calculated
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel px={0}>
              <Stack spacing={5}>
                <Stack spacing={2}>
                  <Heading>1. Deposit</Heading>
                  <Text>
                    This is usually around 10% of the car’s price. Just like a
                    personal loan, the larger your deposit the less you borrow,
                    and the less your monthly payments will be.
                  </Text>
                  <Image src={hirePurchaseChart1} />
                </Stack>
                <Divider />
                <Stack spacing={2}>
                  <Heading>2. Interest</Heading>
                  <Text>
                    This is the additional amount you pay on top of how much you
                    borrow. The longer the length of deal and larger the amount
                    you’re borrowing the more you’ll pay in interest.
                  </Text>
                  <Image src={hirePurchaseChart2} />
                </Stack>
                <Stack spacing={2}>
                  <Heading>3. Monthly Payments</Heading>
                  <Text>
                    Monthly payment are calculated by taking the amount you’re
                    borrowing and the interest and dividing by the length of the
                    contract. The longer the length of deal, the smaller the
                    monthly payments end up being.
                  </Text>
                  <Image src={hirePurchaseChart3} />
                </Stack>
                <Stack spacing={2}>
                  <Heading>4. End of the contract</Heading>
                  <Text>
                    After the final payment is made, the car is yours. You can
                    then choose whether you keep it, sell it, or part-exchange
                    it for a new car or deal.
                  </Text>
                  <Image src={hirePurchaseChart3} />
                </Stack>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Box px={6} py={4} bg="#F7F7FC" borderRadius={16} my={3}>
          <Heading fontSize={16} mb={4} lineHeight="24px">
            A few more things to know about Hire Purchase
          </Heading>
          <Text>
            > There might be charges if you want to pay off the car early
            <br />
            <br />
            > If you fail to keep up the payments, they’ll take the car to cover
            the debt
            <br />
            <br />> You don’t technically own the car until the final payment is
            made, so during the contract you can’t sell the car without
            permission.
          </Text>
        </Box>
      </Stack>
    </Stack>
  );
}

export default HirePurchaseGuideView;
