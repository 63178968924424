const QUIZ_SCORE_LOOKUP = {
  1: {
    1: {
      PCP: -2,
      HP: 2,
      LEASE: -2,
      LOAN: 2
    },
    2: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    3: {
      PCP: 2,
      HP: -2,
      LEASE: 2,
      LOAN: -2
    }
  },
  2: {
    1: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    2: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    3: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    4: {
      PCP: -2,
      HP: -1,
      LEASE: -1,
      LOAN: 2
    }
  },
  3: {
    1: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    2: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    3: {
      PCP: -1,
      HP: -1,
      LEASE: -2,
      LOAN: 2
    }
  },
  4: {
    1: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    2: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    3: {
      PCP: 0,
      HP: 2,
      LEASE: 0,
      LOAN: 2
    }
  },
  5: {
    1: {
      PCP: 2,
      HP: 1,
      LEASE: 2,
      LOAN: 1
    },
    2: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    3: {
      PCP: 1,
      HP: 2,
      LEASE: 0,
      LOAN: 2
    }
  },
  6: {
    1: {
      PCP: -1,
      HP: 2,
      LEASE: -2,
      LOAN: 2
    },
    2: {
      PCP: 1,
      HP: 2,
      LEASE: 1,
      LOAN: 2
    },
    3: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    }
  },
  7: {
    1: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    2: {
      PCP: 2,
      HP: 1,
      LEASE: 2,
      LOAN: 1
    },
    3: {
      PCP: 1,
      HP: 0,
      LEASE: 2,
      LOAN: 0
    }
  },
  8: {
    1: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    2: {
      PCP: -2,
      HP: -1,
      LEASE: -2,
      LOAN: 2
    }
  },
  9: {
    1: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    2: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    3: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 1
    },
    4: {
      PCP: 1,
      HP: 1,
      LEASE: 1,
      LOAN: 0
    }
  },
  10: {
    1: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    2: {
      PCP: 2,
      HP: 2,
      LEASE: 2,
      LOAN: 2
    },
    3: {
      PCP: 2,
      HP: 2,
      LEASE: -2,
      LOAN: 2
    }
  }
};

export default QUIZ_SCORE_LOOKUP;
