import {
  Button,
  Box,
  Heading,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
  InputRightElement,
  IconButton,
  Link,
  Image,
  FormControl,
  FormErrorMessage
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";

import cameraIcon from "../images/camera-icon.svg";
import regInputGB from "../images/reg-input-gb.svg";
import StyledInput from "../components/StyledInput";
import StyledLink from "../components/StyledLink";

function CarRegView() {
  const { fName, lName } = useParams();
  const validate = (value) => {
    if (!value) return "Please enter/select a value";
  };

  return (
    <Formik initialValues={{ carReg: "" }}>
      {({ values, isValid, dirty }) => (
        <Stack spacing={7} py={2} px={4} flex="1 0 auto" minHeight="500px">
          <Text fontSize="12px" style={{ textTransform: "uppercase" }}>
            About the car
          </Text>
          <Stack>
            <Heading size="sm">Let’s start with the car</Heading>
            <Text>
              Enter the registration of the car you want to buy below. Or take a
              photo of the plate by pressing the camera button.
            </Text>
            <Box pt="20px">
              <Form autoComplete="off">
                <Field name="carReg" validate={validate}>
                  {({ field, form }) => (
                    <FormControl
                      id="carReg"
                      isInvalid={form.errors.carReg && form.touched.carReg}
                    >
                      <InputGroup>
                        <InputLeftAddon
                          height="66px"
                          width="44px"
                          px="0"
                          borderRadius="12px"
                          background="#1E438B"
                          style={{ marginTop: "-1px" }}
                        >
                          <img
                            src={regInputGB}
                            alt=""
                            style={{ margin: "0 auto" }}
                          />
                        </InputLeftAddon>
                        <StyledInput
                          {...field}
                          id="carReg"
                          name="carReg"
                          height="64px"
                          placeholder="CU57 ABC"
                          maxLength={9}
                          textTransform="uppercase"
                          style={{
                            borderRadius: "0 12px 12px 0"
                          }}
                        />
                        <InputRightElement m="12px">
                          <IconButton
                            variant="outline"
                            icon={<Image src={cameraIcon} />}
                            borderRadius="12px"
                            minHeight="50px"
                            minWidth="50px"
                            background="white"
                          />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>{form.errors.carReg}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Form>
            </Box>
          </Stack>
          <StyledLink>I don’t have the registration number</StyledLink>
          <Box>
            <Link
              as={RouterLink}
              to={`/reg-results/${fName}/${lName}/${values.carReg}`}
            >
              <Button
                size="lg"
                minWidth="150px"
                colorScheme="blue"
                isDisabled={!isValid || !dirty}
              >
                Find the car
              </Button>
            </Link>
          </Box>
        </Stack>
      )}
    </Formik>
  );
}

export default CarRegView;
