import { Route } from "react-router-dom";

import IntroView from "./views/IntroView";
import HomeView from "./views/HomeView";
import QuizQuestionsView from "./views/QuizQuestionsView";
import LoadingQuizResultsView from "./views/LoadingQuizResultsView";
import QuizResultsView from "./views/QuizResultsView";
import CalculatorView from "./views/CalculatorView";
import CarRegView from "./views/CarRegView";
import RegResultsView from "./views/RegResultsView";
import LoanLengthView from "./views/LoanLengthView";
import PersonalDetailsView from "./views/PersonalDetailsView";
import HouseholdDetailsView from "./views/HouseholdDetailsView";
import EmploymentDetailsView from "./views/EmploymentDetailsView";
import ResultsMoreDetailsView from "./views/ResultsMoreDetailsView";
import LoadingFinalResultsView from "./views/LoadingFinalResultsView";

import BudgetView from "./views/BudgetView";
import DepositView from "./views/DepositView";
import MileageView from "./views/MileageView";
import ResultsView from "./views/ResultsView";
import QuickDetailsView from "./views/QuickDetailsView";
import FinancialGuideView from "./views/FinancialGuideView";
import HirePurchaseGuideView from "./views/HirePurchaseGuideView";
import PcpGuideView from "./views/PcpGuideView";
import PersonalLoanGuideView from "./views/PersonalLoanGuideView";
import LeaseGuideView from "./views/LeaseGuideView";

import ResetLocalStorage from "./views/ResetLocalStorage";

function Routes() {
  return (
    <>
      <Route path="/reset" component={ResetLocalStorage} />
      {/*Guides*/}
      <Route path="/financial-guide/lease" component={LeaseGuideView} />
      <Route
        path="/financial-guide/personal-loan"
        component={PersonalLoanGuideView}
      />
      <Route path="/financial-guide/pcp" component={PcpGuideView} />
      <Route
        path="/financial-guide/hire-purchase"
        component={HirePurchaseGuideView}
      />
      <Route exact path="/financial-guide" component={FinancialGuideView} />

      {/*Journey*/}
      <Route
        path="/more-details/:id/:carReg/:carPrice/:depositValue?/:carMileage/:budget/:loanLength/:firstName/:totalAmount/:monthlyPayment/:interest/:balloon?"
        component={ResultsMoreDetailsView}
      />
      <Route
        path="/results/:carReg/:carPrice/:depositValue?/:carMileage/:budget/:loanLength/:firstName"
        component={ResultsView}
      />
      <Route
        path="/loading-final-results/:carReg/:carPrice/:depositValue?/:carMileage/:budget/:loanLength/:firstName"
        component={LoadingFinalResultsView}
      />
      <Route
        path="/employment-details/:carReg/:carPrice/:depositValue?/:carMileage/:budget/:loanLength/:firstName"
        component={EmploymentDetailsView}
      />
      <Route
        path="/household-details/:carReg/:carPrice/:depositValue?/:carMileage/:budget/:loanLength/:firstName"
        component={HouseholdDetailsView}
      />
      <Route
        path="/personal-details/:fName/:lName/:carReg/:carPrice/:depositValue?/:carMileage/:budget/:loanLength"
        component={PersonalDetailsView}
      />
      <Route
        path="/loan-length/:fName/:lName/:carReg/:carPrice/:depositValue?/:carMileage/:budget"
        component={LoanLengthView}
      />
      <Route
        path="/budget/:fName/:lName/:carReg/:carPrice/:depositValue?/:carMileage"
        component={BudgetView}
      />
      <Route
        path="/mileage/:fName/:lName/:carReg/:carPrice/:depositValue?"
        component={MileageView}
      />
      <Route
        path="/deposit/:fName/:lName/:carReg/:carPrice"
        component={DepositView}
      />
      <Route
        path="/reg-results/:fName/:lName/:carReg"
        component={RegResultsView}
      />
      <Route path="/car-reg/:fName/:lName" component={CarRegView} />
      <Route path="/calculator/:fName?/:lName?/" component={CalculatorView} />
      <Route path="/quiz-results/:fName/:lName" component={QuizResultsView} />
      <Route
        path="/loading-quiz-results/:fName/:lName"
        component={LoadingQuizResultsView}
      />
      <Route
        path="/quiz-questions/:qNumber/:fName/:lName"
        component={QuizQuestionsView}
      />
      <Route path="/quick-details" component={QuickDetailsView} />
      <Route path="/home" component={HomeView} />
      <Route exact path="/" component={IntroView} />
    </>
  );
}

export default Routes;
