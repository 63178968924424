import { useEffect } from "react";
import { Heading, Stack, Spinner } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";

function LoadingQuizResultsView() {
  const { fName, lName } = useParams();
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push(`/quiz-results/${fName}/${lName}`);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Stack
      spacing={4}
      pt="150px"
      pb="50px"
      px={4}
      flex="1 0 auto"
      align="center"
      position="relative"
      minHeight="calc(100vh - 72px)"
    >
      <Spinner
        thickness="6px"
        speed="1s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
        width="5rem"
        height="5rem"
        mb={4}
      />

      <Heading
        size="md"
        lineHeight="1.4"
        fontSize="24px"
        px="10%"
        textAlign="center"
      >
        Just collecting <br />
         your results
      </Heading>
    </Stack>
  );
}

export default LoadingQuizResultsView;
