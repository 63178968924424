import {
  Heading,
  Stack,
  Text,
  Box,
  HStack,
  ListItem,
  UnorderedList
} from "@chakra-ui/react";

import FinanceGuideCard from "../components/FinanceGuideCard";

import financeGuidePcp from "../images/finance-guide-pcp.svg";
import financeGuideLease from "../images/finance-guide-lease.svg";
import financeGuideHirePurchase from "../images/finance-guide-hire-purchase.svg";
import financeGuidePersonalLoan from "../images/finance-guide-personal-loan.svg";

function FinancialGuideView() {
  return (
    <>
      <Stack py={2} px={6} flex="1 0 auto">
        <Stack spacing={6}>
          <Heading fontSize={24}>Car finance made simple</Heading>
          <Text>
            There are a number of different types of car finance that you can
            choose from, each with it’s own set of advantages and disadvantages.
            Understanding each of them, and choosing the right one for you, can
            be the difference between a good experience and a nightmare.
          </Text>
          <Box mb={8}>
            <Heading size="sm" mb={2}>
              The four main types are:
            </Heading>
            <UnorderedList pl={2}>
              <ListItem>Personal Contract Purchase (PCP)</ListItem>
              <ListItem>Hire Purchase</ListItem>
              <ListItem>Personal Loans</ListItem>
              <ListItem>Leasing</ListItem>
            </UnorderedList>
          </Box>
        </Stack>
      </Stack>
      <Stack flex="1 0 auto" bg="#EFF0F6">
        <HStack shouldWrapChildren overflowX="auto" spacing={6} p={3}>
          <FinanceGuideCard
            heading="PCP"
            description="The finance option which includes an optional final payment or you can return the car."
            link="/financial-guide/pcp"
            costBreakdownImage={financeGuidePcp}
            featureList={[
              "Prioritises lower monthly costs",
              "Flexibility at the end, choose what’s best for you at the time."
            ]}
          />
          <FinanceGuideCard
            heading="Lease"
            description="The alternative to buying a vehicle, long-term rental or leasing."
            link="/financial-guide/lease"
            costBreakdownImage={financeGuideLease}
            featureList={[
              "Prioritises lower monthly costs",
              "Hassle-free, no need to worry at the end of the deal"
            ]}
          />
          <FinanceGuideCard
            heading="Hire Purchase"
            description="The straightforward car finance option, with a deposit and fixed monthly payments."
            link="/financial-guide/hire-purchase"
            costBreakdownImage={financeGuideHirePurchase}
            featureList={[
              "Prioritises lower total costs",
              "Own the car when you’ve finished paying"
            ]}
          />
          <FinanceGuideCard
            heading="Personal Loan"
            description="A regular loan from a bank or building society instead of specific car finance."
            link="/financial-guide/personal-loan"
            costBreakdownImage={financeGuidePersonalLoan}
            featureList={[
              "Prioritises lower total costs",
              "Own the car from day one, and an optional deposit "
            ]}
          />
        </HStack>
      </Stack>
    </>
  );
}

export default FinancialGuideView;
