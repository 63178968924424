import {
  Button,
  Box,
  Heading,
  Stack,
  Link,
  Text,
  HStack
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";

import ResultCard from "../components/ResultCard";
import QuizResultsTitle from "../components/QuizResultsTitle";
import StyledLink from "../components/StyledLink";

import SCORE_LOOKUP from "../data/quizScoreLookup";
import RESULT_CARD_LOOKUP from "../data/resultCardLookup";
import makeQuizResultFeatureList from "../helpers/makeQuizResultFeatureList";

let reduceSuitableFinanceTypes = [];

function QuizResultsView() {
  const quizAnswers = JSON.parse(localStorage.getItem("quizAnswers"));
  const { fName, lName } = useParams();

  if (!quizAnswers || !Object.keys(quizAnswers).length) {
    localStorage.setItem(
      "quizAnswers",
      JSON.stringify({
        1: 2,
        2: 2,
        3: 2,
        4: 2,
        5: 2,
        6: 3,
        7: 1,
        8: 1,
        9: 2,
        10: 2
      })
    );
    return null;
  }

  if (quizAnswers[1] === 0) {
    quizAnswers[1] = 2;
  }
  if (quizAnswers[2] === 0) {
    quizAnswers[2] = 2;
  }
  if (quizAnswers[3] === 0) {
    quizAnswers[3] = 2;
  }
  if (quizAnswers[4] === 0) {
    quizAnswers[4] = 2;
  }
  if (quizAnswers[5] === 0) {
    quizAnswers[5] = 2;
  }
  if (quizAnswers[6] === 0) {
    quizAnswers[6] = 3;
  }
  if (quizAnswers[7] === 0) {
    quizAnswers[7] = 1;
  }
  if (quizAnswers[8] === 0) {
    quizAnswers[8] = 1;
  }
  if (quizAnswers[9] === 0) {
    quizAnswers[9] = 2;
  }
  if (quizAnswers[10] === 0) {
    quizAnswers[10] = 2;
  }
  localStorage.setItem("quizAnswers", JSON.stringify(quizAnswers));

  const scores = Object.entries(quizAnswers).reduce(
    (total, answer) => {
      const questionNumber = Number(answer[0]);
      const answerValue = answer[1];
      const { PCP, HP, LEASE, LOAN } = SCORE_LOOKUP[questionNumber][
        answerValue
      ];
      total = [
        ...total,
        {
          questionNumber,
          PCP: total[questionNumber - 1].PCP + PCP,
          HP: total[questionNumber - 1].HP + HP,
          LEASE: total[questionNumber - 1].LEASE + LEASE,
          LOAN: total[questionNumber - 1].LOAN + LOAN
        }
      ];
      return total;
    },
    [{ questionNumber: 0, PCP: 0, HP: 0, LEASE: 0, LOAN: 0 }]
  );

  const finalResults = scores.reduce((total, score, index) => {
    const totalQuestions = Object.keys(quizAnswers).length;
    if (index === totalQuestions) {
      total = [
        { financeType: "PCP", score: score.PCP / (totalQuestions * 2) },
        { financeType: "HP", score: score.HP / (totalQuestions * 2) },
        { financeType: "LEASE", score: score.LEASE / (totalQuestions * 2) },
        { financeType: "LOAN", score: score.LOAN / (totalQuestions * 2) }
      ];
      return total;
    }
    return total;
  }, []);

  const sortedFinalResults = finalResults
    .sort((a, b) => b.financeType.localeCompare(a.financeType))
    .sort((a, b) => b.score - a.score);

  localStorage.setItem(
    "sortedFinalResults",
    JSON.stringify(sortedFinalResults)
  );

  const suitableFinanceTypes = sortedFinalResults.reduce((acc, item) => {
    if (item.score >= 0.75) {
      return [...acc, item];
    }
    return [...acc];
  }, []);

  localStorage.setItem(
    "suitableFinanceTypes",
    JSON.stringify(suitableFinanceTypes)
  );

  if (suitableFinanceTypes.length === 4) {
    reduceSuitableFinanceTypes = suitableFinanceTypes.reduce((acc, item) => {
      const lowScore = Math.min(
        ...suitableFinanceTypes.map(({ score }) => score)
      );
      const highScore = Math.max(
        ...suitableFinanceTypes.map(({ score }) => score)
      );
      if (lowScore === highScore) {
        return [...acc, item];
      }
      if (item.score > lowScore) {
        return [...acc, item];
      }
      return acc;
    }, []);

    localStorage.setItem(
      "suitableFinanceTypes",
      JSON.stringify(reduceSuitableFinanceTypes)
    );
  }

  const featureList = makeQuizResultFeatureList(quizAnswers);

  return (
    <>
      <Stack
        spacing={4}
        px={4}
        pb={10}
        pt={4}
        bg="#163F6D"
        color="white"
        flex="1 0 auto"
      >
        <Stack spacing={4} textAlign="center">
          <Heading fontSize="18px" lineHeight={"24px"} mt={4}>
            <span style={{ textTransform: "capitalize" }}>{fName}</span>, based
            on your answers{" "}
            <QuizResultsTitle
              suitableFinanceTypes={suitableFinanceTypes}
              reduceSuitableFinanceTypes={reduceSuitableFinanceTypes}
              resultCardLookup={RESULT_CARD_LOOKUP}
            />
          </Heading>
        </Stack>
        <HStack
          shouldWrapChildren
          overflowX="auto"
          spacing={4}
          py={3}
          style={{ alignItems: "flex-start" }}
        >
          {sortedFinalResults.map((item) => (
            <ResultCard
              key={item.financeType}
              heading={RESULT_CARD_LOOKUP[item.financeType].heading}
              description={RESULT_CARD_LOOKUP[item.financeType].description}
              suitabilityScore={item.score}
              featureList={featureList.filter(
                (feature) => feature.type === item.financeType
              )}
              guidelink={RESULT_CARD_LOOKUP[item.financeType].guidelink}
              setYellowBg={
                reduceSuitableFinanceTypes.length
                  ? !reduceSuitableFinanceTypes
                      .map(({ financeType }) => financeType)
                      .includes(item.financeType)
                  : false
              }
            />
          ))}
        </HStack>
        <Box pt={2} align="center">
          <StyledLink color="#ffffff" fontSize="13px" textAlign="center">
            Explain what suitability means
          </StyledLink>
        </Box>
      </Stack>
      <Box
        mx="auto"
        width="0"
        height="0"
        borderLeft="30px solid transparent"
        borderRight="30px solid transparent"
        borderTop="30px solid #163F6D"
        mb={10}
      ></Box>
      <Stack
        spacing={4}
        py={2}
        px={4}
        flex="1 0 auto"
        textAlign="center"
        align="center"
      >
        <Heading size="md">
          Now let’s find your best <br />
          finance deals
        </Heading>
        <Text size="13px" maxWidth="90%" textAlign="center" mb={6}>
          By answering just a few more questions, we can search the market,
          compare deals of these types for you and find you the best offers. Or,
          use our calculator to get a rough estimate of costs first.
        </Text>
        <Link as={RouterLink} to={`/car-reg/${fName}/${lName}`} width="300px">
          <Button isFullWidth colorScheme="blue" size="lg" height="60px">
            Get a full quote
          </Button>
        </Link>
        <Link
          as={RouterLink}
          to={`/calculator/${fName}/${lName}`}
          width="300px"
        >
          <Button
            isFullWidth
            variant="outline"
            colorScheme="blue"
            size="lg"
            height="60px"
          >
            Use our calculator
          </Button>
        </Link>
      </Stack>
    </>
  );
}

export default QuizResultsView;
