import {
  Text,
  useRadioGroup,
  Stack,
  Image,
  Grid,
  GridItem
} from "@chakra-ui/react";
import RadioCard from "./RadioCard";

import blueCar from "../images/q8a.png";
import redCar from "../images/q8b.png";

function BinaryRadioGroup({ options, onChange, selectedAnswer }) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "",
    onChange
  });
  const group = getRootProps();
  return (
    <Grid {...group} templateColumns="repeat(2, 1fr)" gap={2}>
      {options.map((value, index) => {
        const radio = getRadioProps({ value });
        return (
          <GridItem key={value}>
            <RadioCard
              {...radio}
              isChecked={index + 1 === selectedAnswer}
              width="50%"
            >
              <Stack spacing={4}>
                <Image src={index ? redCar : blueCar} />
                <Text fontSize={12}>{value}</Text>
              </Stack>
            </RadioCard>
          </GridItem>
        );
      })}
    </Grid>
  );
}

export default BinaryRadioGroup;
