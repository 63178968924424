import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import Layout from "./components/Layout";
import ScrollToTop from "./components/ScrollToTop";
import Routes from "./Routes";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Route
          render={({ location }) => {
            if (location.pathname.startsWith("/quiz-questions"))
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={location.key}
                    classNames="slide-fade"
                    addEndListener={(node, done) =>
                      node.addEventListener("transitionend", done, false)
                    }
                  >
                    <Routes />
                  </CSSTransition>
                </SwitchTransition>
              );
            else {
              return (
                <Switch location={location}>
                  <Routes />
                </Switch>
              );
            }
          }}
        />
      </Layout>
    </Router>
  );
}

export default App;
