import { Stack, Heading, Button, Link, Box, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import logoLight from "../images/logo-light.svg";

function IntroVIew() {
  localStorage.clear();

  // initialise quizAnswers in local storage
  const quizAnswers = JSON.parse(localStorage.getItem("quizAnswers"));
  if (!quizAnswers || !Object.keys(quizAnswers).length) {
    localStorage.setItem(
      "quizAnswers",
      JSON.stringify({
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0
      })
    );
  }
  return (
    <Stack py={24} px={10} spacing={14} height="100vh" flex="1 0 auto">
      <img src={logoLight} alt="Clear Drive" />
      <Stack spacing={2}>
        <Heading size="md" lineHeight="1.4" mb={4} textAlign="center">
          Welcome to the
          <br />
          ClearDrive prototype
        </Heading>
        <Text textAlign="center">
          This prototype was created to show the future experience of using
          ClearDrive. It was created for internal sharing, and isn't a
          specification.
        </Text>
      </Stack>
      <Box width="100%">
        <Link as={RouterLink} to="/home">
          <Button colorScheme="blue" isFullWidth>
            Start prototype
          </Button>
        </Link>
      </Box>
    </Stack>
  );
}

export default IntroVIew;
