const FINAL_RESULTS = [
  {
    id: 1,
    type: "PCP",
    companyName: "CreditPlus",
    customerRating: 5,
    phoneSupport: true,
    noEarlyRepayment: false,
    eligibility: 100,
    apr: 0.069
  },
  {
    id: 2,
    type: "HP",
    companyName: "Paragon",
    customerRating: 5,
    phoneSupport: true,
    noEarlyRepayment: true,
    eligibility: 100,
    apr: 0.055
  },
  {
    id: 3,
    type: "Loan",
    companyName: "AA Loans",
    customerRating: 5,
    phoneSupport: true,
    noEarlyRepayment: true,
    eligibility: 90,
    apr: 0.045
  },
  {
    id: 4,
    type: "Lease",
    companyName: "Select Leasing UK",
    customerRating: 4,
    phoneSupport: false,
    noEarlyRepayment: true,
    eligibility: 85,
    apr: 0.15
  },
  {
    id: 5,
    type: "PCP",
    companyName: "Zuto",
    customerRating: 4,
    phoneSupport: true,
    noEarlyRepayment: true,
    eligibility: 90,
    apr: 0.065
  },
  {
    id: 6,
    type: "PCP",
    companyName: "Car Finance 247",
    customerRating: 3,
    phoneSupport: true,
    noEarlyRepayment: false,
    eligibility: 80,
    apr: 0.055
  },
  {
    id: 7,
    type: "HP",
    companyName: "Zuto",
    customerRating: 3,
    phoneSupport: false,
    noEarlyRepayment: true,
    eligibility: 80,
    apr: 0.049
  },
  {
    id: 8,
    type: "Loan",
    companyName: "Car Loan 4U",
    customerRating: 5,
    phoneSupport: true,
    noEarlyRepayment: true,
    eligibility: 95,
    apr: 0.097
  },
  {
    id: 9,
    type: "HP",
    companyName: "Black Horse",
    customerRating: 4,
    phoneSupport: true,
    noEarlyRepayment: true,
    eligibility: 90,
    apr: 0.039
  },
  {
    id: 10,
    type: "Loan",
    companyName: "Barclays",
    customerRating: 5,
    phoneSupport: false,
    noEarlyRepayment: true,
    eligibility: 85,
    apr: 0.115
  },
  {
    id: 11,
    type: "Lease",
    companyName: "Zuto",
    customerRating: 4,
    phoneSupport: true,
    noEarlyRepayment: false,
    eligibility: 95,
    apr: 0.035
  },
  {
    id: 12,
    type: "Lease",
    companyName: "Paragon",
    customerRating: 3,
    phoneSupport: true,
    noEarlyRepayment: true,
    eligibility: 80,
    apr: 0.065
  },
  {
    id: 13,
    type: "Loan",
    companyName: "Santander",
    customerRating: 3,
    phoneSupport: true,
    noEarlyRepayment: true,
    eligibility: 75,
    apr: 0.1
  },
  {
    id: 14,
    type: "PCP",
    companyName: "Black Horse",
    customerRating: 4,
    phoneSupport: true,
    noEarlyRepayment: true,
    eligibility: 75,
    apr: 0.095
  },
  {
    id: 15,
    type: "Loan",
    companyName: "Amigo",
    customerRating: 3,
    phoneSupport: false,
    noEarlyRepayment: false,
    eligibility: 75,
    apr: 0.074
  }
];

export default FINAL_RESULTS;
