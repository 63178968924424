import { useState } from "react";
import { Box, Button, Heading, Stack, Text, Link } from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import StyledInput from "../components/StyledInput";
import StyledSelect from "../components/StyledSelect";

const RESIDENTIAL_OPTIONS = [
  "Please Select",
  "Own home with mortgage",
  "Own home no mortgage",
  "Private renting",
  "Council tenant",
  "Living with parents",
  "Other"
];

const TIME_OPTIONS = [
  "Please Select",
  "Less than 1 year",
  "1-2 Years",
  "2-3 Years",
  "Over 3 Years"
];

function HouseholdDetailsView() {
  const {
    carReg,
    carPrice,
    depositValue,
    carMileage,
    budget,
    loanLength,
    firstName
  } = useParams();

  const [postcode, setPostcode] = useState("");
  const [findAddressClicked, setFindAddressClicked] = useState(false);
  return (
    <Stack py={2} px={4} spacing={7} flex="1 0 auto">
      <Text fontSize="12px" style={{ textTransform: "uppercase" }}>
        Your household details
      </Text>

      <Stack spacing={4}>
        <Heading size="sm">Your postcode</Heading>
        <StyledInput
          placeholder="e.g. SW1A 2AA"
          onChange={(e) =>
            !findAddressClicked &&
            setPostcode(e.target.value.toLocaleUpperCase())
          }
          value={postcode}
        />
        {!findAddressClicked && (
          <Button
            colorScheme="blue"
            size="lg"
            variant="outline"
            maxWidth="200px"
            onClick={() => setFindAddressClicked(true)}
            disabled={!postcode}
          >
            Find address
          </Button>
        )}
        {findAddressClicked && (
          <>
            <Heading size="sm">Please select your address</Heading>
            <StyledSelect mb={4}>
              <option>1 example street, {postcode}</option>
              <option>2 example street, {postcode}</option>
              <option>3 example street, {postcode}</option>
              <option>4 example street, {postcode}</option>
            </StyledSelect>
          </>
        )}
      </Stack>
      <Stack>
        <Heading size="sm">What is your residential status?</Heading>
        <Stack>
          <StyledSelect>
            {RESIDENTIAL_OPTIONS.map((value) => (
              <option key={value}>{value}</option>
            ))}
          </StyledSelect>
        </Stack>
      </Stack>

      <Stack>
        <Heading size="sm">
          How long have you lived at this address for?
        </Heading>
        <Stack>
          <StyledSelect>
            {TIME_OPTIONS.map((value) => (
              <option key={value}>{value}</option>
            ))}
          </StyledSelect>
        </Stack>
      </Stack>

      <Box>
        <Link
          as={RouterLink}
          to={`/employment-details/${carReg}/${carPrice}/${depositValue}/${carMileage}/${budget}/${loanLength}/${firstName}`}
        >
          <Button colorScheme="blue" size="lg">
            Next - Employment status
          </Button>
        </Link>
      </Box>
    </Stack>
  );
}

export default HouseholdDetailsView;
