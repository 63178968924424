import { Text } from "@chakra-ui/react";
import { PropTypes } from "prop-types";

const QuizResultsTitle = ({
  suitableFinanceTypes,
  reduceSuitableFinanceTypes,
  resultCardLookup
}) => {
  if (reduceSuitableFinanceTypes.length) {
    if (reduceSuitableFinanceTypes.length === 1) {
      return (
        <>
          <Text as="span" color="#FEE4A1">
            {
              resultCardLookup[reduceSuitableFinanceTypes[0].financeType]
                .heading
            }
          </Text>
          is the most suitable for your needs.
        </>
      );
    }
    if (reduceSuitableFinanceTypes.length === 2) {
      return (
        <>
          <Text as="span" color="#FEE4A1">
            {
              resultCardLookup[reduceSuitableFinanceTypes[0].financeType]
                .heading
            }
          </Text>{" "}
          and{" "}
          <Text as="span" color="#FEE4A1">
            {
              resultCardLookup[reduceSuitableFinanceTypes[1].financeType]
                .heading
            }
          </Text>{" "}
          are the most suitable for your needs.
        </>
      );
    }

    if (reduceSuitableFinanceTypes.length === 3) {
      return (
        <>
          <Text as="span" color="#FEE4A1">
            {
              resultCardLookup[reduceSuitableFinanceTypes[0].financeType]
                .heading
            }
          </Text>
          {", "}
          <Text as="span" color="#FEE4A1">
            {
              resultCardLookup[reduceSuitableFinanceTypes[1].financeType]
                .heading
            }
          </Text>{" "}
          and{" "}
          <Text as="span" color="#FEE4A1">
            {
              resultCardLookup[reduceSuitableFinanceTypes[2].financeType]
                .heading
            }
          </Text>{" "}
          are the most suitable for your needs.
        </>
      );
    }
    if (reduceSuitableFinanceTypes.length === 4) {
      return "All four finance types are suitable, check the details to find the best one for you. ";
    }
  }

  if (suitableFinanceTypes.length === 3) {
    return (
      <>
        <Text as="span" color="#FEE4A1">
          {resultCardLookup[suitableFinanceTypes[0].financeType].heading}
        </Text>
        {", "}
        <Text as="span" color="#FEE4A1">
          {resultCardLookup[suitableFinanceTypes[1].financeType].heading}
        </Text>{" "}
        and{" "}
        <Text as="span" color="#FEE4A1">
          {resultCardLookup[suitableFinanceTypes[2].financeType].heading}
        </Text>{" "}
        are the most suitable for your needs.
      </>
    );
  }

  if (suitableFinanceTypes.length === 2) {
    return (
      <>
        <Text as="span" color="#FEE4A1">
          {resultCardLookup[suitableFinanceTypes[0].financeType].heading}
        </Text>{" "}
        and{" "}
        <Text as="span" color="#FEE4A1">
          {resultCardLookup[suitableFinanceTypes[1].financeType].heading}
        </Text>{" "}
        are the most suitable for your needs.
      </>
    );
  }
  if (suitableFinanceTypes.length === 1) {
    return (
      <>
        <Text as="span" color="#FEE4A1">
          {resultCardLookup[suitableFinanceTypes[0].financeType].heading}
        </Text>{" "}
        is the most suitable for your needs.
      </>
    );
  }

  return <Text>No suitable results</Text>;
};

QuizResultsTitle.propTypes = {
  suitableFinanceTypes: PropTypes.array.isRequired,
  reduceSuitableFinanceTypes: PropTypes.array.isRequired,
  resultCardLookup: PropTypes.object.isRequired
};

export default QuizResultsTitle;
