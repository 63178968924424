import { useState } from "react";
import {
  Box,
  Button,
  Heading,
  Spacer,
  Flex,
  Stack,
  Link,
  Text,
  Tabs,
  TabList,
  TabPanels,
  UnorderedList,
  ListItem,
  Tab,
  TabPanel
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";

import qQuestions from "../data/quizQuestions";
import Slider from "../components/Slider";
import StyledCurrencyInput from "../components/StyledCurrencyInput";

const budgetValueLookup = {
  1: 100,
  2: 150,
  3: 200,
  4: 250,
  5: 300,
  6: 350,
  7: 400,
  8: 450,
  9: 500
};

const durationValueLookup = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5
};

function CalculatorView() {
  const [budgetValue, setBudgetValue] = useState(5);
  const [durationValue, setDurationValue] = useState(3);
  const [deposit, setDeposit] = useState("");

  const { fName, lName } = useParams();

  const quizAnswers = JSON.parse(localStorage.getItem("quizAnswers"));
  if (!quizAnswers || !Object.keys(quizAnswers).length) {
    localStorage.setItem(
      "quizAnswers",
      JSON.stringify({
        1: 2,
        2: 2,
        3: 2,
        4: 2,
        5: 2,
        6: 3,
        7: 1,
        8: 1,
        9: 2,
        10: 2
      })
    );
    return null;
  }

  const creditRating = qQuestions[9].options[Number(quizAnswers[9]) - 1];
  const total =
    Number(deposit) +
    12 * durationValueLookup[durationValue] * budgetValueLookup[budgetValue];

  const financeAmount =
    (total - Number(deposit)) /
    Math.pow(
      1 + 0.04 / durationValueLookup[durationValue],
      durationValueLookup[durationValue]
    );

  return (
    <Stack spacing={12} py={2} px={4} flex="1 0 auto">
      <Stack spacing={4}>
        <Heading size="md">Get a quick cost estimate</Heading>
        <Text>
          Find out how much you could spend on a car with the finance deals that
          best match your situation.
        </Text>
      </Stack>
      <Stack spacing={8}>
        <Stack flex="1 0 auto">
          <Heading size="sm" lineHeight="1.8">
            Your deposit or upfront payment
          </Heading>
          <StyledCurrencyInput
            width="80%"
            value={deposit}
            onValueChange={({ value }) => {
              setDeposit(value);
            }}
            pattern="\d*"
          />
        </Stack>

        <Stack>
          <Flex width="90%">
            <Heading size="sm" lineHeight="1.8">
              My monthly budget
            </Heading>
            <Spacer />
            <Text fontWeight="bold" color="#2969B0">
              £{budgetValueLookup[budgetValue]}
            </Text>
          </Flex>
          <Slider
            onChange={(val) => setBudgetValue(val)}
            value={budgetValue}
            min={1}
            max={9}
            width="90%"
          />
        </Stack>
        <Stack>
          <Flex width="90%">
            <Heading size="sm" lineHeight="1.8">
              Length of deal
            </Heading>
            <Spacer />
            <Text fontWeight="bold" color="#2969B0">
              {durationValueLookup[durationValue]}{" "}
              {durationValueLookup[durationValue] === 1 ? "Year" : "Years"}
            </Text>
          </Flex>
          <Slider
            onChange={(val) => setDurationValue(val)}
            value={durationValue}
            min={1}
            max={5}
            width="90%"
          />
        </Stack>
      </Stack>
      <Box>
        <Tabs>
          <TabList>
            <Tab fontSize="13px" px="12px">
              Hire Purchase
            </Tab>
            <Tab fontSize="13px" px="12px">
              Personal Loan
            </Tab>
            <Tab fontSize="13px" px="12px">
              Lease
            </Tab>
            <Tab fontSize="13px" px="12px">
              PCP
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Box background="#EFF0F6" mx="-2rem" mt="-1rem" p={6} mb="110px">
                <Box px={2} mb="-98px">
                  <Stack
                    background="#163F6D"
                    flex="1 0 auto"
                    height="158px"
                    borderRadius="5px"
                    boxShadow="md"
                    color="#ffffff"
                    textAlign="center"
                    p={4}
                  >
                    <Text fontSize="16px">You could get a car worth</Text>
                    <Heading fontSize="40px">
                      £{Number(financeAmount.toFixed(0)) + Number(deposit)}
                    </Heading>
                    <Text fontSize="13px">
                      *This is based on a credit rating, that you told us was{" "}
                      {creditRating}
                    </Text>
                  </Stack>
                </Box>
              </Box>
              <Box borderBottom="1px solid #999" pb={6} mb={6}>
                <Text fontSize="13px" mb={2}>
                  {durationValueLookup[durationValue] * 12} monthly payments of
                </Text>
                <Heading fontSize="24px" color="#2969B0" mb={6}>
                  £{budgetValueLookup[budgetValue]}
                </Heading>
                <Text fontWeight="bold" fontSize="13px" mb={2}>
                  Breakdown of costs
                </Text>
                <Flex width="80%">
                  {deposit && (
                    <>
                      <Text fontSize="12px">Deposit</Text>
                      <Spacer />
                      <Text fontSize="12px">£{deposit}</Text>
                    </>
                  )}
                </Flex>
                <Flex width="80%">
                  <Text fontSize="12px">Finance amount</Text>
                  <Spacer />
                  <Text fontSize="12px">£{financeAmount.toFixed(0)}</Text>
                </Flex>
                <Flex width="80%">
                  <Text fontSize="12px">Interest</Text>
                  <Spacer />
                  <Text fontSize="12px">
                    £{total - financeAmount.toFixed(0) - deposit}
                  </Text>
                </Flex>
                <Flex width="80%" mt={2}>
                  <Text fontWeight="bold" fontSize="12px">
                    Total paid
                  </Text>
                  <Spacer />
                  <Text fontWeight="bold" fontSize="12px">
                    £{total}
                  </Text>
                </Flex>
              </Box>
              <Box>
                <UnorderedList spacing={2} fontSize="12px">
                  <ListItem>
                    The repayments will not go up or down for the duration of
                    the deal.
                  </ListItem>

                  <ListItem>
                    There may be charges if you pay off the car early.
                  </ListItem>

                  <ListItem>
                    You won’t technically own the car until the final payment.
                    So if you want to sell it before it’s fully paid for, you’ll
                    need their permission.
                  </ListItem>

                  <ListItem>
                    If you fall behind in payments the car can be taken back to
                    cover the debt.
                  </ListItem>
                </UnorderedList>
              </Box>
            </TabPanel>
            <TabPanel>
              <p>Unavailable in prototype</p>
            </TabPanel>
            <TabPanel>
              <p>Unavailable in prototype</p>
            </TabPanel>
            <TabPanel>
              <p>Unavailable in prototype</p>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Box align="center">
        <Link as={RouterLink} to={`/car-reg/${fName}/${lName}`}>
          <Button colorScheme="blue" height="60px" px="40px">
            Get a full quote
          </Button>
        </Link>
        <Text fontSize="13px" mt={6} width="80%">
          We’ll search our panel of 30 trusted providers for Hire Purchase and
          Personal Loan deals.
        </Text>
      </Box>
    </Stack>
  );
}

export default CalculatorView;
