const quizQuestions = {
  1: {
    question:
      "What matters more to you, lower monthly payments or keeping the overall cost down?",
    wdtmText: "",
    wdtmLink: "What does this mean?",
    wdtmHeading: "What does this mean?",
    wdtmContent:
      "Lower monthly repayments make a car more affordable in the short to medium term. But you’ll pay more overall and not own the car from day one. Keep the overall cost lower, and you’ll pay more each month. With some deals you own the car from day-one.",
    confirmHeading: "So you know…",
    confirmContent: [
      "This means you pay more each month, but for less time. You could  own the car from day one too.",
      "That’s no problem, we’ll keep your options open here.",
      "This could make a car more affordable over the short to medium term. But you could pay more overall."
    ],
    options: ["Overall lower cost", "I don't mind", "Lower monthly payments"]
  },
  2: {
    question:
      "Any savings for a deposit to pay up front, or a car you're going to trade in?",
    wdtmText:
      "Having a deposit or car to trade in will give you more flexibility and could reduce your overall costs.",
    wdtmLink: "",
    wdtmHeading: "Heading",
    wdtmContent: "Content",
    confirmHeading: "Confirm heading",
    confirmContent: ["", "", "", ""],
    options: [
      "I have savings",
      "I have a car to trade in",
      "I have savings and a car to trade-in",
      "I don't have either"
    ]
  },
  3: {
    question: "How important is owning your new car from day one?",
    wdtmText: "",
    wdtmLink: "What does this mean?",
    wdtmHeading: "What does this mean?",
    wdtmContent:
      "Your preference affects the deals you’re offered. Some don’t let you own the car until final payments are made.",
    confirmHeading: "Confirm heading",
    confirmContent: [
      "This could lower your monthly payments and give you the opportunity to change cars sooner.",
      "That’s no problem, we’ll keep your options open in this area.",
      "Some finance deals don’t allow you to own the car from day-one."
    ],
    options: ["Not important", "I don't mind either way", "It's important"]
  },
  4: {
    question: "How often do you use your car?",
    wdtmText:
      "With some deals, car usage affects potential costs and repayments. Understanding your usage helps us suggest the right finance type.",
    wdtmLink: "",
    wdtmHeading: "Heading",
    wdtmContent: "Content",
    confirmHeading: "Confirm heading",
    confirmContent: [
      "We expect this means you're driving less than 8,000 miles a year.",
      "This likely means you're driving between 8,000 and 13,000 miles a year.",
      "We expect this means you're driving more than 13,000 miles a year."
    ],
    options: [
      "I rarely drive",
      "Shops, work, and leisure",
      "I drive everywhere"
    ]
  },
  5: {
    question: "How long will you keep the car for?",
    wdtmText:
      "The time you intend to keep a car for alters the deals best suited to you. Some deals are set up for changing the car regularly, where as others are great for keeping the car for a long time.",
    wdtmLink: "",
    wdtmHeading: "Heading",
    wdtmContent: "Content",
    confirmHeading: "Confirm heading",
    confirmContent: ["", "", ""],
    options: [
      "Less than 3 years",
      "I change every 3 to 5 years",
      "Over 5 years"
    ]
  },
  6: {
    question: "Let’s talk about general wear and tear",
    wdtmText:
      "Some deals (that involve returning the car) carry penalties if they’re handed back damaged.",
    wdtmLink: "",
    wdtmHeading: "Heading",
    wdtmContent: "Content",
    confirmHeading: "Confirm heading",
    confirmContent: ["", "", ""],
    options: [
      "My cars get a lot of dents and scratches",
      "The odd scratch, but nothing major",
      "I keep my car in perfect condition"
    ]
  },
  7: {
    question: "Do you want help with the servicing and upkeep of your car?",
    wdtmText:
      "Some deals offer servicing and maintenance packages at an additional cost. It’s worth considering if you’d like to pay a single monthly payment that covers everything related to your car’s upkeep.",
    wdtmLink: "",
    wdtmHeading: "Heading",
    wdtmContent: "Content",
    confirmHeading: "Confirm heading",
    confirmContent: ["", "", ""],
    options: ["No thanks", "Interested but depends on the cost", "Yes please"]
  },
  8: {
    question:
      "Will you modify your car - such as changing the colour, adding new wheel trims, or adding a tow-bar?",
    wdtmText: "",
    wdtmLink: "What does this mean?",
    wdtmHeading: "What does this mean?",
    wdtmContent:
      "Some finance deals don’t allow you to change the appearance of the car or tinker with its performance.",
    confirmHeading: "Confirm heading",
    confirmContent: [
      "Keeping the car as is means all finance types will work for you.",
      "Some deals don’t let you change the appearance of the car or modify it."
    ],
    options: [
      "I’ll keep the car as it is",
      "I probably would do some work on it"
    ]
  },
  9: {
    question: "What do you think your credit rating is?",
    wdtmText:
      "Your credit rating affects the kinds of car finance you’ll be offered and the level of repayments.",
    wdtmLink: "Help me get my credit rating",
    wdtmHeading: "",
    wdtmContent: "",
    confirmHeading: "Confirm heading",
    confirmContent: ["", "", "", ""],
    options: ["Excellent", "Good", "Fair", "Poor"]
  },
  10: {
    question: "How old is the car likely to be when you buy it?",
    wdtmText:
      "The age of the car will affect the kind of finance deals that are suitable for you.",
    wdtmLink: "",
    wdtmHeading: "Heading",
    wdtmContent: "Content",
    confirmHeading: "Confirm heading",
    confirmContent: ["", "", ""],
    options: ["Brand new", "Probably under 3 years", "Over 3 years"]
  }
};
export default quizQuestions;
