import PropTypes from "prop-types";
import {
  Button,
  Box,
  Heading,
  Stack,
  Text,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from "@chakra-ui/react";

import BinaryRadioGroup from "../components/BinaryRadioGroup";
import TernaryRadioGroup from "../components/TernaryRadioGroup";
import StyledLink from "../components/StyledLink";

import q1 from "../images/q1.png";
import q2 from "../images/q2.png";
import q3 from "../images/q3.png";
import q4 from "../images/q4.png";
import q5 from "../images/q5.png";
import q6 from "../images/q6.png";
import q7 from "../images/q7.png";
import q8 from "../images/q8a.png";
import q9 from "../images/q9.png";
import q10 from "../images/q10.png";

const Q_IMG_LOOKUP = {
  1: q1,
  2: q2,
  3: q3,
  4: q4,
  5: q5,
  6: q6,
  7: q7,
  8: q8,
  9: q9,
  10: q10
};

function QuizQuestion({
  questionNumber,
  question,
  wdtmText,
  wdtmLink,
  wdtmHeading,
  wdtmContent,
  options,
  onAnswerSelect,
  selectedAnswer
}) {
  const {
    isOpen: isWdtmOpen,
    onOpen: onWdtmOpen,
    onClose: onWdtmClose
  } = useDisclosure({ id: "WDTM" });

  return (
    <Stack py={2} px={0} flex="1 0 auto">
      <Stack alignItems="center" spacing={6} mt={6}>
        {(options.length === 3 || options.length === 4) && (
          <Box height="125px">
            <Image
              src={Q_IMG_LOOKUP[questionNumber]}
              mt={2}
              mb={10}
              maxHeight="125px"
            />
          </Box>
        )}
        {options.length === 2 && <Box height="20px"></Box>}
        <Heading size="sm" textAlign="center" width="75%">
          {question}
        </Heading>
        <Box textAlign="center" width="100%">
          <StyledLink
            fontSize="sm"
            onClick={onWdtmOpen}
            mt={questionNumber === 9 ? -2 : 0}
            mb={questionNumber === 9 ? 2 : 0}
            display="block"
          >
            {wdtmLink}
          </StyledLink>

          <Text fontSize="13px" maxWidth="80%" mx="auto" mb={6}>
            {wdtmText}
          </Text>
        </Box>
        <Modal isOpen={isWdtmOpen} onClose={onWdtmClose} isCentered>
          <ModalOverlay />
          <ModalContent p={3} mb="200px" maxWidth="350px">
            <ModalHeader>{wdtmHeading}</ModalHeader>
            <ModalCloseButton _focus={{ outline: 0 }} />
            <ModalBody>{wdtmContent}</ModalBody>
            <ModalFooter background="white">
              <Button colorScheme="blue" onClick={onWdtmClose} isFullWidth>
                Okay, thanks
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Stack spacing={2} width="100%">
          {options.length === 2 && (
            <BinaryRadioGroup
              options={options}
              onChange={(value) => onAnswerSelect(value)}
              selectedAnswer={selectedAnswer}
            />
          )}
          {options.length === 3 && (
            <TernaryRadioGroup
              options={options}
              onChange={(value) => onAnswerSelect(value)}
              selectedAnswer={selectedAnswer}
            />
          )}
          {options.length === 4 && (
            <Stack spacing={2} mt={-6} mb={-4}>
              {options.length === 4 &&
                options.map((value, index) => (
                  <Button
                    key={value}
                    isFullWidth
                    variant={index + 1 === selectedAnswer ? "solid" : "outline"}
                    colorScheme="blue"
                    onClick={() => onAnswerSelect(value)}
                  >
                    {value}
                  </Button>
                ))}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

QuizQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired,
  wdtmHeading: PropTypes.string.isRequired,
  wdtmContent: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedAnswer: PropTypes.number
};

export default QuizQuestion;
