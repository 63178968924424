import PropTypes from "prop-types";
import {
  Slider as ChakraSlider,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack
} from "@chakra-ui/react";

function Slider({ onChange, value, ...rest }) {
  return (
    <ChakraSlider onChange={onChange} value={value} {...rest}>
      <SliderTrack height="6px" borderRadius="12px">
        <SliderFilledTrack height="12px" color="#2969B0" />
      </SliderTrack>
      <SliderThumb
        width="25px"
        height="25px"
        border="2px solid"
        borderColor="#2969B0"
      />
    </ChakraSlider>
  );
}

Slider.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired
};

export default Slider;
