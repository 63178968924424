import { Box, Container } from "@chakra-ui/react";
import { withRouter } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import useWindowDimensions from "../hooks/useWindowDimensions";

function Layout({ children, location }) {
  const { width } = useWindowDimensions();

  return (
    <Box height="100vh" background="#efefef">
      <Container
        maxWidth={width < 500 ? "420px" : "375px"}
        display="flex"
        flexDirection="column"
        p={0}
        bg="white"
        maxHeight="100vh"
        overflowY="auto"
        overflowX="hidden"
      >
        {location.pathname !== "/" && (
          <Header
            dark={
              location.pathname.startsWith("/home") ||
              location.pathname.startsWith("/results") ||
              location.pathname.startsWith("/quiz-results") ||
              location.pathname.startsWith("/more-details")
            }
          />
        )}
        {children}
        {location.pathname === "/" ||
        location.pathname.startsWith("/quiz-questions") ||
        location.pathname.startsWith("/loading") ||
        location.pathname.startsWith("/quick-details") ? null : (
          <Footer />
        )}
      </Container>
    </Box>
  );
}

export default withRouter(Layout);
