import {
  Heading,
  Stack,
  Text,
  Box,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
  Divider
} from "@chakra-ui/react";

import { ArrowBackIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";

import pcpGuideCosts from "../images/pcp-guide-costs.svg";
import pcpGuideChart1 from "../images/pcp-guide-chart-1.svg";
import pcpGuideChart2 from "../images/pcp-guide-chart-2.svg";
import pcpGuideChart3 from "../images/pcp-guide-chart-3.svg";
import pcpGuideChart4 from "../images/pcp-guide-chart-4.svg";

function PcpGuideView() {
  const history = useHistory();
  return (
    <Stack py={2} px={6} flex="1 0 auto">
      <Stack spacing={6}>
        <Link color="#2969B0" onClick={() => history.goBack()}>
          <ArrowBackIcon />
          Back
        </Link>
        <Heading>Personal Contract Purchase – PCP</Heading>
        <Heading fontSize={16}>
          The finance option which includes an optional final payment or you can
          return the car.
        </Heading>
        <Text>
          This is one of the most common ways to finance a car, with 75% of new
          car finance, and 38% of used car finance being PCP.
          <br />
          <br />
          It’s also one of the more complex options, leaving a lot of people
          scratching their heads about whether they are getting a good deal or
          not.
        </Text>
        <Heading fontSize={16}>How it works</Heading>
        <Text>
          Personal Contract Purchase finance (PCP) is a bit like an
          interest-only mortgage. Your monthly payments are only paying off the
          loss in value of the car during your finance deal.
          <br /> <br />
          It stands out from the other finance options by including both a
          deposit at the start, and a final payment (or “balloon payment”) at
          the end of the finance deal. Both of these help to keep monthly
          repayments lower. Helping people to save money, get a better car, or
          to go for a shorter finance duration.
        </Text>
        <Image src={pcpGuideCosts} />
        <Accordion allowToggle p={0}>
          <AccordionItem px={0}>
            <AccordionButton py={4} px={0}>
              <Heading color="blue.500" textAlign="left" fontSize={20}>
                See how the numbers are calculated
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel px={0}>
              <Stack spacing={5}>
                <Stack spacing={2}>
                  <Heading>1. Deposit</Heading>
                  <Text>
                    This is usually around 10% of the car’s price. Just like a
                    personal loan, the larger your deposit the less you borrow,
                    and the less your monthly payments will be.
                  </Text>
                  <Image src={pcpGuideChart1} />
                </Stack>
                <Divider />
                <Stack spacing={2}>
                  <Heading>2. Calculate final optional payment</Heading>
                  <Text>
                    To help reduce your monthly payments with PCP, you’ll borrow
                    less by not paying off the full value of the car with the
                    monthly repayments.
                    <br />
                    <br />
                    Instead, they calculate a final payment, which is how much
                    the dealer expects your car to be worth after your finance
                    deal ends. you’re borrowing the more you’ll pay in interest.
                  </Text>
                  <Image src={pcpGuideChart2} />
                </Stack>
                <Stack spacing={2}>
                  <Heading>3. Interest</Heading>
                  <Text>
                    This is the additional amount you pay on top of how much you
                    borrow. The longer the length of deal and larger the amount
                    you’re borrowing the more you’ll pay in interest. There is
                    also interest on the final payment, but this only applies at
                    the end of your deal.
                  </Text>
                  <Image src={pcpGuideChart3} />
                </Stack>
                <Stack spacing={2}>
                  <Heading>4. Monthly payments</Heading>
                  <Text>
                    It’s quite likely these will be lower with PCP than they
                    would be for an equivalent sized loan. That’s because you
                    don’t own the car unless you choose to make a larger final
                    ‘balloon payment’.
                  </Text>
                  <Image src={pcpGuideChart4} />
                </Stack>
                <Stack spacing={5}>
                  <Heading>5. Your final payment options</Heading>
                  <Text>
                    At the end of your monthly payments, you have three options
                  </Text>
                  <Heading>A. Return the car</Heading>
                  <Text>
                    You simply hand back the keys to the dealer and walk away.
                    As long as you’ve kept within your mileage agreement and
                    there isn’t excess damage (beyond normal wear and tear) to
                    the car you don’t have to pay anything.
                  </Text>
                  <Heading>B. Pay the final payment & keep the car</Heading>
                  <Text>
                    Once paid, you’ll own the car outright. The size of payment
                    is essentially the minimum it would be worth if sold, plus
                    the interest on the final payment amount.
                  </Text>
                  <Heading>C. Trade-in for another car</Heading>
                  <Text>
                    You can use the car as deposit for a new one and simply
                    start a new PCP deal with the same dealer.
                  </Text>
                </Stack>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Box px={6} py={4} bg="#F7F7FC" borderRadius={16} my={3}>
          <Heading fontSize={16} mb={4} lineHeight="24px">
            A few more things to know about Personal Contract Purchases
          </Heading>
          <Text>
            > There might be charges if you want to pay off the car early
            <br />
            <br />> If you fail to keep up the payments, they’ll take the car to
            cover the debt
            <br />
            <br />
            > You’ll need to agree a mileage upfront so they can calculate the
            final payment - exceeding this mileage will potentially result in
            some additional charges at the end.
            <br />
            <br />> If you damage the car, more than usual wear and tear, there
            might be charges if you choose to return the car at the end.
          </Text>
        </Box>
      </Stack>
    </Stack>
  );
}

export default PcpGuideView;
