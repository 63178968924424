import { Box, useRadio } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

function CustomRadio(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  return (
    <Box as="label" width="33.3%" position="relative">
      <input {...input} />
      <Box
        {...checkbox}
        borderRadius="50%"
        border="1px solid #163F6D"
        bg="white"
        width="24px"
        height="24px"
        px="3px"
        mx="auto"
        position="relative"
        _checked={{
          bg: "#2969B0",
          color: "white"
        }}
        _after={
          !props.isEndElement && {
            content: "''",
            width: "110px",
            borderBottom: "1px solid #163F6D",
            position: "absolute",
            top: "50%",
            left: "23px"
          }
        }
      >
        {input.checked && <CheckIcon />}
      </Box>
      {props.children}
    </Box>
  );
}

export default CustomRadio;
