import {
  Box,
  Stack,
  Text,
  Heading,
  Link,
  List,
  ListItem,
  ListIcon,
  Image
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";

function FinanceGuideCard({
  heading,
  description,
  link,
  costBreakdownImage,
  featureList
}) {
  return (
    <Box
      borderRadius="16px"
      bg="white"
      boxShadow="md"
      color="black"
      py={8}
      maxWidth="320px"
    >
      <Stack>
        <Heading fontSize={24} px={6}>
          {heading}
        </Heading>
        <Text px={6}>
          {description} <br />
          <Link color="#2969B0" as={RouterLink} to={link}>
            More about {heading}
          </Link>
        </Text>
        <Box bg="#FFF9EF" p={6}>
          <Image src={costBreakdownImage} />
        </Box>
        <List spacing={3} px={6} fontSize={14} pt={2}>
          {featureList.map((feature) => (
            <ListItem key={feature} d="flex" alignItems="center">
              <ListIcon color="green.500" as={CheckCircleIcon} />
              {feature}
            </ListItem>
          ))}
        </List>
      </Stack>
    </Box>
  );
}

export default FinanceGuideCard;
