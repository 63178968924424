import { Box, useRadio } from "@chakra-ui/react";

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" height="100%">
      <input {...input} />
      <Box
        {...checkbox}
        border="1px solid"
        borderRadius={5}
        p={4}
        cursor="pointer"
        textAlign="center"
        _checked={{
          bg: "#2969B0",
          color: "white",
          borderColor: "#2969B0"
        }}
        height="100%"
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default RadioCard;
