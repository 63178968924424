import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Button, Box, Flex, Link } from "@chakra-ui/react";

import logoLight from "../images/logo-light.svg";
import logoDark from "../images/logo-dark.svg";
import menuIconLight from "../images/menu-icon-light.svg";
import menuIconDark from "../images/menu-icon-dark.svg";

function Header({ dark }) {
  return (
    <Box bg={dark ? "#163F6D" : "white"} px={4}>
      <Flex my={6} justifyContent="space-between">
        <Link as={RouterLink} to="/home">
          <img src={dark ? logoDark : logoLight} alt="Clear Drive" />
        </Link>
        <Button variant="link">
          <img src={dark ? menuIconDark : menuIconLight} alt="Menu" />
        </Button>
      </Flex>
    </Box>
  );
}

Header.propTypes = {
  dark: PropTypes.bool
};

export default Header;
