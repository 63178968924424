import { useState } from "react";
import {
  Button,
  Box,
  Heading,
  Stack,
  Text,
  Link,
  Input,
  Grid
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import StyledCurrencyInput from "../components/StyledCurrencyInput";

const EMPLOYMENT_OPTIONS = [
  "Full time",
  "Part time",
  "Self employed",
  "Student",
  "Retired",
  "Unemployed",
  "Other"
];

function EmploymentDetailsView() {
  const {
    carReg,
    carPrice,
    depositValue,
    carMileage,
    budget,
    loanLength,
    firstName
  } = useParams();
  const [selectedEmploymentType, setSelectedEmploymentType] = useState("");
  return (
    <Stack py={2} px={4} spacing={7} flex="1 0 auto">
      <Text fontSize="12px" style={{ textTransform: "uppercase" }}>
        Your employment status
      </Text>
      <Stack>
        <Heading size="sm">What is your employment status?</Heading>
        <Grid templateColumns="repeat(2,1fr)" gap={2} pt={4}>
          {EMPLOYMENT_OPTIONS.map((type) => (
            <Button
              size="lg"
              key={type}
              colorScheme="blue"
              onClick={() => setSelectedEmploymentType(type)}
              variant={type === selectedEmploymentType ? "solid" : "outline"}
            >
              {type}
            </Button>
          ))}
        </Grid>
      </Stack>
      {(selectedEmploymentType === "Full time" ||
        selectedEmploymentType === "Part time" ||
        selectedEmploymentType === "Self employed") && (
        <>
          <Stack>
            <Heading size="sm">Job title</Heading>
            <Input
              size="lg"
              style={{
                background: "#EFF0F7",
                border: "2px solid #2969B0",
                borderRadius: "12px"
              }}
            />
          </Stack>
          <Stack>
            <Heading size="sm">Employer's name</Heading>
            <Input
              size="lg"
              style={{
                background: "#EFF0F7",
                border: "2px solid #2969B0",
                borderRadius: "12px"
              }}
            />
          </Stack>
          <Stack>
            <Heading size="sm">How long have you been in this role?</Heading>
            <Input
              size="lg"
              placeholder="e.g. 3 years"
              style={{
                background: "#EFF0F7",
                border: "2px solid #2969B0",
                borderRadius: "12px"
              }}
              pattern="\d*"
            />
          </Stack>
          <Stack>
            <Heading size="sm">Salary</Heading>
            <StyledCurrencyInput placeholder="e.g. 25000" pattern="\d*" />
          </Stack>
        </>
      )}

      <Box>
        <Link
          as={RouterLink}
          to={`/loading-final-results/${carReg}/${carPrice}/${depositValue}/${carMileage}/${budget}/${loanLength}/${firstName}`}
        >
          <Button size="lg" colorScheme="blue">
            Next - the final section
          </Button>
        </Link>
      </Box>
    </Stack>
  );
}

export default EmploymentDetailsView;
