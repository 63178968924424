import { Link } from "@chakra-ui/react";

function StyledLink({ children, ...props }) {
  return (
    <Link color="#2969B0" textDecoration="underline" {...props}>
      {children}
    </Link>
  );
}

export default StyledLink;
