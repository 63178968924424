import { useState } from "react";
import {
  Button,
  Box,
  Heading,
  Stack,
  Text,
  Link,
  Grid
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";

const LOAN_LENGTH_OPTIONS = [1, 2, 3, 4, 5];

function LoanLengthView() {
  const [loanLength, setLoanLength] = useState(0);
  const {
    fName,
    lName,
    carReg,
    carPrice,
    depositValue,
    carMileage,
    budget
  } = useParams();
  return (
    <Stack spacing={7} py={2} px={4} flex="1 0 auto" minHeight="500px">
      <Text fontSize="12px" style={{ textTransform: "uppercase" }}>
        Finance details
      </Text>
      <Stack spacing={4}>
        <Heading size="sm" lineHeight="1.8">
          What length of time do you want to spread your payments over?
        </Heading>
        <Text fontSize={13} lineHeight="1.8">
          A shorter finance term usually costs less in total but carries higher
          monthly repayments. Longer terms reduce monthly payments but probably
          cost more.
        </Text>
        <Text fontSize={13} lineHeight="1.8" mb={2}>
          You can change this later when you get the results.
        </Text>
        <Grid templateColumns="repeat(2,1fr)" gap={2} pt={4}>
          {LOAN_LENGTH_OPTIONS.map((opt) => (
            <Button
              size="lg"
              key={opt}
              colorScheme="blue"
              onClick={() => setLoanLength(opt)}
              variant={opt === loanLength ? "solid" : "outline"}
            >
              {opt} {opt > 1 ? "years" : "year"}
            </Button>
          ))}
        </Grid>
      </Stack>
      <Box>
        <Link
          as={RouterLink}
          to={`/personal-details/${fName}/${lName}/${carReg}/${carPrice}/${depositValue}/${carMileage}/${budget}/${loanLength}`}
        >
          <Button colorScheme="blue" size="lg" disabled={!loanLength}>
            Next - Your personal details
          </Button>
        </Link>
      </Box>
    </Stack>
  );
}

export default LoanLengthView;
